import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import React from "react";
import {useHistory} from "react-router";
import {IFilterData} from "../../../Hooks/FiltersHandler";
import {ICampagneModificationCoordonnee} from "src/Models/CampagneModificationCoordonnee.model";
import {CampagneModificationCoordonneesConstants} from "../../../Constants/CampagneModificationCoordonneesConstants";
import {Link} from "react-router-dom";
import CourriersHelper from "../../../Helpers/Courriers.helper";

type ICampagneModificationsOfficesTableListComponentProps = {
    filters: IFilterData,
    setOrder: (value: string) => void,
    campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[],
}

export default function CampagneModificationsCoordonneesTableListComponent(props: ICampagneModificationsOfficesTableListComponentProps) {
    const {filters, setOrder, campagnesModificationsCoordonnees} = props;
    const history = useHistory();

    // Trouver le dernier courrier (id le plus grand)
    const getLastCourrier = (campagneModificationCoordonnee: ICampagneModificationCoordonnee) => {
        return campagneModificationCoordonnee.courriers?.reduce((max, courrier) => (courrier.id > max.id ? courrier : max),
            campagneModificationCoordonnee.courriers?.[0]
        );
    }

    return (
        <>
            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {[
                        {slug: "officeCRPCEN", label: "CRPCEN", sortable: true},
                        {slug: "officeNom", label: "Nom", sortable: true},
                        {slug: "etat", label: "Etat", sortable: true},
                        {slug: "notaire", label: "Notaire", sortable: false},
                        {slug: "ancienneValeur", label: "Ancienne Valeur *", sortable: true},
                        {slug: "nouvelleValeur", label: "Nouvelle Valeur", sortable: false},
                        {slug: "choixAffichageDomaineEmail", label: "Choix d'affichage", sortable: true},
                        {slug: "sousDomainePersonnalise", label: "Sous domaine personnalisé", sortable: false},
                        {slug: "statut-dernier-mail", label: "Statut du dernier mail", sortable: false},
                    ].map((item, iIndex) => <th className="th" key={iIndex}>
                        <TableHeaderButtonComponent
                            direction={filters.orderType as string || AppConstants.orderType.asc}
                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                            noSort={!item.sortable}
                            label={item.label} click={(value: string) => setOrder(value)}/>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {campagnesModificationsCoordonnees.map((campagneModificationCoordonnee: ICampagneModificationCoordonnee, index: number) => {
                    console.log(getLastCourrier(campagneModificationCoordonnee));
                    return (
                        <tr key={index}
                            onClick={() => history.push(`/annuaire/campagnes-modifications-coordonnees/${campagneModificationCoordonnee.id}`)}
                            className="tr">
                            <td className="td -bold">
                                <Link
                                    to={`/annuaire/campagnes-modifications-coordonnees/${campagneModificationCoordonnee.id}`}
                                    target="_blank">
                                    {campagneModificationCoordonnee.officeCRPCEN}
                                </Link>
                            </td>
                            <td className="td -w200px">
                                {campagneModificationCoordonnee.officeNom}
                            </td>
                            <td className="td">
                                <span
                                    className={`state-bullet icon-bullet -${campagneModificationCoordonnee.etat === 'a-confirmer-par-email' || campagneModificationCoordonnee.etat === 'a-valider-par-chambre' || campagneModificationCoordonnee.etat === 'choix-affichage-a-preciser' ? 'a-faire' : campagneModificationCoordonnee.etat === 'coordonnee-remplacee' || campagneModificationCoordonnee.etat === 'validee-par-chambre' ? 'realisee' : ''}`}>&nbsp;</span>
                                <span>
                                    {CampagneModificationCoordonneesConstants.etatsOptions.find(option => option.value === campagneModificationCoordonnee.etat)?.label}
                                </span>
                            </td>
                            <td className="td">
                                {campagneModificationCoordonnee.notaireNom ?
                                    campagneModificationCoordonnee.notaireNom + ' ' + campagneModificationCoordonnee.notairePrenom : '-'}
                            </td>
                            <td className="td">
                                {campagneModificationCoordonnee.ancienneValeur || "-"}
                            </td>
                            <td className="td">
                                {campagneModificationCoordonnee.nouvelleValeur || "-"}
                            </td>
                            <td className="td">
                                {CampagneModificationCoordonneesConstants.choixAffichageDomaineEmailOptions.find(option => option.value === campagneModificationCoordonnee.choixAffichageDomaineEmail)?.label ?? '-'}
                            </td>
                            <td className="td">
                                {campagneModificationCoordonnee.sousDomainePersonnalise || "-"}
                            </td>
                            <td className="td">
                                {getLastCourrier(campagneModificationCoordonnee)  ?
                                    <>
                                        <span
                                            className={`state-bullet icon-bullet -${getLastCourrier(campagneModificationCoordonnee).mailjetCurrentStatus ?? 'empty'}`}>&nbsp;</span>
                                        <span>
                                            {CourriersHelper.getCurrentStatusLabel(getLastCourrier(campagneModificationCoordonnee))}
                                        </span>
                                    </>: "-"}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="g-content">
                <div className="information-text -small">
                    <p>
                        <strong>Ancienne Valeur *</strong> : Le tri sur cette colonne est effectuée par office à partir du CRPCEN (croissant ou décroissant) puis ensuite sur l'ancienne valeur (croissant).
                    </p>
                </div>
            </div>
        </>
    )
}
