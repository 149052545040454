import React, {useEffect, useState} from "react";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "../FormCreator.component";
import {
    IApiCustomResponse,
    IApiErrorMessage,
    IApiFieldsErrorMessages,
    IFileDownloadApiResponse
} from "../../../Services/Api.service";
import {CourriersService, ICourriersTypesGetApiResponse} from "../../../Services/Courriers.service";
import {ICourrierType} from "../../../Models/Courrier.model";
import CourriersHelper from "../../../Helpers/Courriers.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "../../../Shared/config";
import {
    CampagneModificationCoordonneesService,
    ICampagneModificationCoordonneeCourrierFormData
} from "../../../Services/CampagneModificationCoordonneesService";
import {ICampagneModificationCoordonnee} from "../../../Models/CampagneModificationCoordonnee.model";

type ICampagneModificationCoordonneeCourrierFormProps = {
    campagneModificationCoordonnee: ICampagneModificationCoordonnee,
    campagneModificationCoordonneeCallback: () => void,
}

export default function CampagneModificationCoordonneeCourrierFormComponent(props: ICampagneModificationCoordonneeCourrierFormProps){

    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const campagneModificationCoordonneeService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();
    const [pdfPreviewSrc, setPdfPreviewSrc] = useState<string>(null);

    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();

        courriersService.getCourriersTypesForModule('annuaire/campagnes-modifications-coordonnees').then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                setCourriersTypes(response.datas.types);
            }
        });
    }, [])

    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length) return;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        ];

        for(let type of courriersTypes){

            formElementsColumn1.push(
                {
                    type: 'text',
                    fieldName: "subjectMail",
                    key: "subjectMail" + type.id.toString(),

                    label: "Sujet du mail",
                    placeholder: "Sujet de l'email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.sujet ? type.sujet : "",

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );

            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenuMail",

                    label: "Contenu Email",
                    placeholder: "Contenu Email",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.contenuMail ? type.contenuMail : "",

                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        const formElementsColumn2: FormElement[] = [];

        formElementsColumn2.push({
            type: 'previewPDF',
            oldValue: pdfPreviewSrc,
            label: "Prévisualisation",
            icon: "icon-voir",
            action: () => setPdfPreviewSrc(null),
        });

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-size-2 -size-2-w-margin",
                elements: formElementsColumn1
            },
            {
                modificators: "-size-2 -pos-sticky",
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false,onActionEnded)}
            },
            {
                label: "Prévisualiser",
                modificators: "-primary",
                icon: "icon-voir",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {
                    onDownload(formData, false, onActionEnded, true);
                }
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes, pdfPreviewSrc]);

    const onDownload = (formData: FormComponentFormData, isDebug: boolean, onActionEnded?: () => void, isPreview?: boolean): void => {

        setPdfPreviewSrc(null);

        const formDataUpdated: ICampagneModificationCoordonneeCourrierFormData = {
            ...formData as ICampagneModificationCoordonneeCourrierFormData,
            isDebug: isDebug ?? false,
            isPreview: isPreview ?? false,
            campagneModificationId: props.campagneModificationCoordonnee.id,
        }

        campagneModificationCoordonneeService.downloadCourrier(props.campagneModificationCoordonnee.id, formDataUpdated).then((response: IFileDownloadApiResponse) => {
            if (onActionEnded) onActionEnded();

            if (response && response.datas && response.datas.file) {
                if (isPreview) {
                    setPdfPreviewSrc(response.datas.file);
                }
            }

        }, (error: IApiCustomResponse) => {

            if (onActionEnded) onActionEnded();

            if (error.messages) {
                setErrorsMessage(error.messages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la prévisualisation.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {

        setPdfPreviewSrc(null);

        const formDataUpdated: ICampagneModificationCoordonneeCourrierFormData = {
            ...formData as ICampagneModificationCoordonneeCourrierFormData,
            isDebug: isDebug ?? false,
            campagneModificationId: props.campagneModificationCoordonnee.id,
        }

        campagneModificationCoordonneeService.sendCourrier(props.campagneModificationCoordonnee.id, formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });
            props.campagneModificationCoordonneeCallback();

        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    }

    return(
        <div className={`fiche-content -right-gutter border-footer`}>
            <div className={`g-content form-wrapper -edit`}>
                {
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages}
                    />
                }

            </div>
        </div>
    );
}
