import React, {useEffect} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {IInspection} from "src/Models/Inspection.model";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import OfficesHelper from "src/Helpers/Offices.helper";
import SelectMultipleFieldComponent from "../../Fields/SelectMultiple.field.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {InspectionsConstants} from "src/Constants/InspectionsConstants";
import {IInspecteur} from "src/Models/Inspecteur.model";
import InspectionListInspecteurItemComponent from "./InspectionListInspecteurItem.component";
import {useHistory} from "react-router";
import SelectFieldComponent, {IOption} from "../../Fields/Select.field.component";
import InspectionsHelper from "../../../Helpers/Inspections.helper";


type IInspectionListTableComponentProps = {
    inspections: IInspection[],
    filters?: IFilterData,
    setOrder?: (value: string) => void,
    updateFilters?: (data: IFilterData) => void,
    anneeDefaut?: string,
    isTest?: boolean
}

export default function InspectionListTableComponent(props: IInspectionListTableComponentProps) {
    const history = useHistory();

    return (
        <>
            <div className="list-filters">
                {/* Afficher l'année : celle sélectionnée ou celle par défaut */}
                {
                    (props.filters.annee != null || props.anneeDefaut != null) &&
                    <span
                        className={`year`}>Année {props.filters.annee ? props.filters.annee : props.anneeDefaut != null ? props.anneeDefaut : ''}</span>
                }

                <i className={`icon icon-filtre`}/>

                <SelectFieldComponent
                    fieldName="annee"
                    label="Année"
                    options={InspectionsHelper.getListInspectionsAnneesOptions().reverse()}
                    hideSearch={true}
                    errors={null}
                    oldValue={props.filters.annee ? props.filters.annee as string : props.anneeDefaut}
                    modificators={`-inline -on-white`}
                    onChange={value => props.updateFilters({annee: value})}/>

                <SelectMultipleFieldComponent
                    fieldName="departement"
                    label="Départements"
                    options={OfficesConstants.departementsOptions}
                    hideCurrentValuesOnTop={true}
                    errors={null}
                    placeholder="Tous les départements"
                    hideSearch={true}
                    modificators={`-inline -on-white`}
                    oldValues={props.filters.departement ? props.filters.departement as string[] : []}
                    onChange={(values: string[]) => props.updateFilters({departement: values})}/>

                {
                    !props.isTest &&
                    <SelectMultipleFieldComponent
                        fieldName="etat"
                        label="Etat"
                        options={InspectionsConstants.etatsOptions}
                        hideCurrentValuesOnTop={true}
                        errors={null}
                        placeholder="Tous les états"
                        hideSearch={true}
                        modificators={`-inline -on-white`}
                        oldValues={props.filters.etat ? props.filters.etat as string[] : []}
                        onChange={(values: string[]) => props.updateFilters({etat: values})}/>
                }
            </div>


            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {[
                        {slug: "etat", label: "État"},
                        {slug: "annee", label: "Année"},
                        {slug: "CRPCEN", label: "CRPCEN"},
                        {slug: "nomOffice", label: "Office"},
                        {slug: "nbActes", label: "Actes"},
                        {slug: "systemeCompta", label: "Logiciel"},
                        {slug: "inspecteursOrder", label: "Inspecteurs", modificator: "-w300px"},
                        {
                            slug: "inspecteursPreviousOrder",
                            label: "Inspecteurs des inspecteurs",
                            modificator: "-w300px"
                        },
                    ].map((item, iIndex) =>
                        <th className={`th ${item.modificator ? item.modificator : ''}`} key={iIndex}>
                            <TableHeaderButtonComponent
                                direction={props.filters.orderType as string || AppConstants.orderType.asc}
                                value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                                label={item.label}
                                click={props.setOrder ? (value: string) => props.setOrder(value) : null}/>
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {
                    props.inspections && props.inspections.map((inspection: IInspection, index: number) =>
                        <tr key={index}
                            onClick={() => !props.isTest ? history.push(`${AppConstants.pathInspections}/${inspection.id}`) : null}
                            className="tr">
                            <td className="td -bullet">
                                <span className={`state-bullet icon-bullet -${inspection.etat}`} title={InspectionsHelper.getInspectionEtatLabel(inspection.etat)}/>
                            </td>
                            <td className="td">
                                {inspection.annee}
                            </td>
                            <td className="td -bold">
                                {inspection.office.CRPCEN}
                            </td>
                            <td className="td">
                                <strong>{inspection.office.nom}</strong>
                                {
                                    inspection.office.adressePrincipale &&
                                    <>
                                        <br/>
                                        {inspection.office.adressePrincipale.ville}
                                    </>
                                }
                                {
                                    inspection.office.emailsComptables &&
                                    <>
                                        <br/>
                                        {inspection.office.emailsComptables}
                                    </>
                                }
                            </td>
                            <td className="td">
                                {inspection.office.nbActes}
                            </td>
                            <td className="td">
                                {inspection.office.systemeCompta ? OfficesHelper.getFormatedSystemeCompta(inspection.office.systemeCompta) : '-'}
                            </td>
                            <td className="td -multiline -w600px" colSpan={2}>
                                <table className={`list-table -no-margin`}>
                                    <tbody>
                                    {
                                        inspection.inspecteurs && inspection.inspecteurs.map((inspecteur: IInspecteur, index: number) =>
                                            <tr className="tr" key={index}>
                                                <td className={`td -w300px ${(index < (inspection.inspecteurs.length - 1)) ? '-with-border-bottom' : ''}`}>
                                                    <InspectionListInspecteurItemComponent inspecteur={inspecteur}/>
                                                </td>
                                                <td className={`td -w300px ${(index < (inspection.inspecteurs.length - 1)) ? '-with-border-bottom' : ''}`}>
                                                    <p className="line">
                                                        {
                                                            inspecteur.inspecteursCurrentOffice && inspecteur.inspecteursCurrentOffice.map((ins, indexIns) =>
                                                                <InspectionListInspecteurItemComponent
                                                                    inspecteur={ins}
                                                                    key={index + '_' + indexIns}
                                                                    modificators={"-no-border -small"}/>
                                                            )
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </>
    );
}
