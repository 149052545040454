import {ICampagnesModificationsGetApiResponse} from "src/Services/CampagnesModifications.service"
import {
    CampagnesModificationsDonneesService,
    ICampagneModificationDonneeFormData
} from "src/Services/CampagnesModificationsDonneesService";
import {FormColumn, FormElement, FormActions, FormComponentFormData} from "../FormCreator.component";
import FormCreatorComponent from "../FormCreator.component";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import React, {useState, useEffect} from "react";
import {ICampagneModificationDonnee} from "../../../Models/CampagneModificationDonnee.model";
import {IApiCustomResponse} from "src/Services/Api.service";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useContext} from "react";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import {IOption} from "../../Fields/Select.field.component";
import {INotaire} from "../../../Models/Notaire.model";
import NotairesHelper from "../../../Helpers/Notaires.helper";

type ICampagneModificationDonneeFormComponentProps = {
    campagneModificationDonnee?: ICampagneModificationDonnee,
    submitMethod: (formData: ICampagneModificationDonneeFormData) => Promise<ICampagnesModificationsGetApiResponse>,
    onSuccessSubmit: (response: ICampagnesModificationsGetApiResponse) => void,
}

//TODO ATTENTION pour l'instant que Notaire référent Vigilance LCB-FT
//Formulaire d'édition d'UNE campagne de modification de donnée
export default function CampagneModificationDonneeForm(props: ICampagneModificationDonneeFormComponentProps) {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formActions, setFormActions] = useState<FormActions[]>([]);

    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [onConfirm, setOnConfirm] = useState<() => void>();
    const [confirmationText, setConfirmationText] = useState<string>();

    const {isAdmin, isAuthenticated} = useContext(UserContext) as IUserContext;

    const finishedStatuses = ["donnee-remplacee", "validee-par-chambre", "a-valider-par-chambre"];

    const campagnesModificationsDonneesService: CampagnesModificationsDonneesService = new CampagnesModificationsDonneesService();

    /**
     * Initialisation du formulaire à partir des infos de notaires présent pour la campagne
     */
    useEffect(() => {
        // On reset le formAction pour pouvoir faire disparaitre les boutons en cas de campagne finie
        setFormActions([]);

        //todo vérifier que dans les anciennesValeurs, il y a bien un notaire référent Vigilance LCB-FT

        //Pour le notaire choix du notaire référent Vigilance LCB-FT, on ne prend que les notaires associés et individuels de l'office
        let notairesIndividuelsAssociesOfficeOptions: IOption[] = [];

        if(props.campagneModificationDonnee.campagneModificationOffice.office.notaires != null &&
            props.campagneModificationDonnee.campagneModificationOffice.office.notaires.length > 0) {
            const notairesIndividuelsAssocies: INotaire[] = props.campagneModificationDonnee.campagneModificationOffice.office.notaires.filter((notaire) => {
                return notaire.type === 'notaireAssocie' || notaire.type === 'notaireIndividuel';
            });
            notairesIndividuelsAssociesOfficeOptions = NotairesHelper.formatListForOptions(notairesIndividuelsAssocies);
        }

        //Si nouvelles valeurs n'est pas encore défini, on prend les anciennes valeurs pour construire le formulaire des anciennes valeurs
        //todo côté back ?
        let valeurs = props.campagneModificationDonnee.nouvellesValeurs;
        if(valeurs == null || valeurs.length == 0){
            valeurs = props.campagneModificationDonnee.anciennesValeurs;
        }

        //Création d'un répéteur avec une seule ligne pour le choix du notaire référent Vigilance LCB-FT
        const formElementsColumn1: FormElement[] = [
            //Définir le nouvel état à l'envoie du formulaire (si les données sont correctes)
            {
                type: "hidden",
                fieldName: "etat",
                required: true,
                oldValue : "a-valider-par-chambre",
                modificators: "-hidden"
            },
            {
                type: "repeater",
                fieldName: "nouvellesValeurs",
                //label: "Nouvelle(s) valeur(s)",
                label: "Notaire référent Vigilance LCB-FT",
                oldRepeatableValues: valeurs,
                hideAddButton: true,
                hideDeleteButton: true,
                required: true,
                formColumns: [
                    {
                        modificators: "-full",
                        elements: [
                            {
                                type: "hidden",
                                fieldName: "type",
                                required: true,
                                oldValue : "",
                                modificators: "-hidden"
                            },
                            {
                                type: "hidden",
                                fieldName: "champ",
                                required: true,
                                oldValue : "",
                                modificators: "-hidden"
                            },
                            {
                                type: "select",
                                fieldName: "valeur",
                                //label: "Notaire référent Vigilance LCB-FT (nouvelle valeur)",
                                label: "Notaire de votre office",
                                required: true,
                                options: notairesIndividuelsAssociesOfficeOptions,
                                oldValue: "",
                                disabled: finishedStatuses.includes(props.campagneModificationDonnee.etat),
                                modificators: "-on-white",
                                showFieldErrorDetail: true,
                            },
                        ]
                    },
                ],
            },
        ];

        //Création d'un répéteur avec une seule ligne NON EDITABLE pour voir l'ancienne valeur du notaire référent Vigilance LCB-FT
        const formElementsColumn2: FormElement[] = [
            /*
            {
                type: "repeater",
                fieldName: "anciennesValeurs",
                label: "Ancienne(s) valeur(s)",
                oldRepeatableValues: props.campagneModificationDonnee.anciennesValeurs,
                hideAddButton: true,
                hideDeleteButton: true,
                required: true,
                formColumns: [
                    {
                        modificators: "-full",
                        elements: [
                            {
                                type: "select",
                                fieldName: "valeur",
                                label: "Notaire référent Vigilance LCB-FT (ancienne valeur)",
                                disabled: true,
                                options: notairesIndividuelsAssociesOfficeOptions,
                                oldValue: "",
                                modificators: "-on-white",
                            },
                        ]
                    },
                ],
            },
             */
        ];

        const currentFormColumns: FormColumn[] = [];

        currentFormColumns.push({
            elements: formElementsColumn1
        });

        currentFormColumns.push({
            elements: formElementsColumn2
        });

        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaire
        const currentFormActions: FormActions[] = [];

        if (!finishedStatuses.includes(props.campagneModificationDonnee.etat)) {
            //Si on n'est pas à la fin du process,
            // => On affiche l'enregistrement des nouvelles données
            currentFormActions.push({
                label: "Enregistrer",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: false,
                onAction: (formData) => {
                    onSaveNouvelDonnee(formData);
                }
            });
        } else {
            // Si la campagne est en attente de validation par la chambre, on affiche le bouton de validation (si l'utilisateur est connecté au progiciel et est admin)
            if (props.campagneModificationDonnee.etat === "a-valider-par-chambre" && isAuthenticated && isAdmin) {
                currentFormActions.push({
                    label: "Valider",
                    modificators: "-primary",
                    icon: "icon-check",
                    hasLoading: false,
                    onAction: () => {
                        onValidateCampagneModificationDonnee();
                    }
                });
            }
        }

        setFormActions(currentFormActions);

    }, [props.campagneModificationDonnee]);

    /**
     * Soumission du formulaire (pour l'enregistrement de la nouvelle donnée)
     *
     * @param {FormComponentFormData} formData
     */
    const onSaveNouvelDonnee = (formData: FormComponentFormData) => {
        if (!props.submitMethod) return;

        if (props.campagneModificationDonnee && props.campagneModificationDonnee.id) {
            let notificationErreurMessage = "Une erreur est survenue lors de la modification des données.";
            let notificationMessage = "Les données ont bien été modifiées.";

            props.submitMethod(formData as ICampagneModificationDonneeFormData).then((response: ICampagnesModificationsGetApiResponse) => {
                //On reset les erreurs
                setErrorsMessage(null);
                setErrorFieldsMessages(null);

                if (props.onSuccessSubmit) {
                    props.onSuccessSubmit(response);
                }

                if(response.messages[0] != null) {
                    notificationMessage = response.messages[0];
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: notificationMessage,
                    type: "success"
                });

            }, (error: IApiCustomResponse) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                    if(error.messages[0] != null) {
                        notificationErreurMessage = error.messages[0];
                    }
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                if (!error.messages && !error.messages) {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: notificationErreurMessage,
                        type: "danger"
                    });
                }
            });
        } else {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: notificationSystem,
                type: "danger"
            });
        }
    }

    /**
     * Bouton pour la validation de la campagne de modification de donnée
     */
    const onValidateCampagneModificationDonnee = () => {
        //On reset les erreurs
        setErrorsMessage(null);
        setErrorFieldsMessages(null);

        let notificationErreurMessage = "Une erreur est survenue lors de la validation des données.";
        let notificationMessage = "Les données ont bien été validées.";

        campagnesModificationsDonneesService.validation(props.campagneModificationDonnee.id)
            .then((response: ICampagnesModificationsGetApiResponse) => {
                if (response) {
                    if (props.onSuccessSubmit) {
                        props.onSuccessSubmit(response);
                    }

                    if(response.messages[0] != null) {
                        notificationMessage = response.messages[0];
                    }

                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: notificationMessage,
                        type: "success"
                    });
                } else {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: notificationErreurMessage,
                        type: "danger"
                    });
                }
            })
            .catch((error) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                    if(error.messages[0] != null) {
                        notificationErreurMessage = error.messages[0];
                    }
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: notificationErreurMessage,
                    type: "danger"
                });
            });
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
        setOnConfirm(null);
        setConfirmationText(null);
    }

    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent
                    onConfirm={onConfirm}
                    onCancel={onCancelConfirmation}
                    text={confirmationText}
                    confirmText="Supprimer"/>
            }
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions="-mt-20"
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}
            />
        </>
    )
}