import {IOption} from "src/Components/Fields/Select.field.component";

export type IUserRole = {
    [name: string]: string
}
export type IUserConstants = {
    roles: IUserRole,
    rolesOptions: IOption[]
}
export const UserConstants: IUserConstants = {
    roles: {
        superAdmin: "30",
        admin: "20",
        contributor: "10",
        htpwdUser: "5",
    },

    rolesOptions: [
        {
            value: "5",
            label: "Utilisateur authentifié par URL"
        },
        {
            value: "10",
            //label: "Contributeur"
            label: "Collaborateur / Membre de Chambre"
        },
        {
            value: "20",
            label: "Administrateur"
        },
        {
            value: "30",
            label: "Super Administrateur"
        },
    ]
};
