import {ICampagnesModificationsGetApiResponse} from "src/Services/CampagnesModifications.service"
import {
    CampagneModificationCoordonneesService,
    ICampagneModificationCoordonneeFormData
} from "src/Services/CampagneModificationCoordonneesService";
import {FormColumn, FormElement, FormActions, FormComponentFormData} from "../FormCreator.component";
import FormCreatorComponent from "../FormCreator.component";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import React, {useState, useEffect} from "react";
import {ICampagneModificationCoordonnee} from "../../../Models/CampagneModificationCoordonnee.model";
import {IApiCustomResponse} from "src/Services/Api.service";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useContext} from "react";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import CoordonneesHelper from "../../../Helpers/Coordonnees.helper";
import {ICoordonnee} from "../../../Models/Coordonnee.model";
import {CampagneModificationCoordonneesConstants} from "../../../Constants/CampagneModificationCoordonneesConstants";

type ICampagneModificationCoordonneeFormComponentProps = {
    campagneModificationCoordonnee?: ICampagneModificationCoordonnee,
    type: 'notaire' | 'office',
    submitMethod?: (formData: ICampagneModificationCoordonneeFormData) => Promise<ICampagnesModificationsGetApiResponse>,
    onSuccessSubmit?: (response: ICampagnesModificationsGetApiResponse) => void,
    mode?: 'readonly' | 'edit',
}

//Formulaire d'édition d'UNE campagne de modification de coordonnée
export default function CampagneModificationCoordoneeForm(props: ICampagneModificationCoordonneeFormComponentProps) {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formActions, setFormActions] = useState<FormActions[]>([]);

    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [onConfirm, setOnConfirm] = useState<() => void>();
    const [confirmationText, setConfirmationText] = useState<string>();

    const {isAuthenticated, isAdmin} = useContext(UserContext) as IUserContext;

    const adminFinishedStatuses = ["coordonnee-remplacee", "validee-par-chambre", "a-supprimer-par-chambre"];
    const finishedStatuses = [...adminFinishedStatuses, "a-valider-par-chambre"];

    const campagneModificationCoordonneeService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

    /**
     * Initialisation du formulaire à partir des infos de notaires présent pour la campagne
     */
    useEffect(() => {

        // On reset le formAction pour pouvoir faire disparaitre les boutons en cas de campagne finie
        setFormActions([]);

        const formElementsColumn1: FormElement[] = [];
        if (props.type === 'notaire' && props.campagneModificationCoordonnee.notaire) {
            formElementsColumn1.push({
                type: "informations",
                label: "Nom du notaire",
                modificators: "-d-block",
                oldValue: props.campagneModificationCoordonnee.notaire.nom + " " + props.campagneModificationCoordonnee.notaire.prenom,
            });
        }

        //Afficher la destination de la coordonnée (principale, compta, ...)
        let destinationLabel: string = "";
        let coordonnee: ICoordonnee = null;
        if (props.campagneModificationCoordonnee.coordonnee != null &&
            props.campagneModificationCoordonnee.coordonnee.destination != null) {
            coordonnee = props.campagneModificationCoordonnee.coordonnee;
            destinationLabel = CoordonneesHelper.getFormatedDestination(
                coordonnee.destination,
                coordonnee.type,
                props.type,
            )
        }
        if (destinationLabel !== "") {
            formElementsColumn1.push({
                type: "informations",
                label: "Destination",
                modificators: "-d-block",
                oldValue: destinationLabel,
            });
        }
        const formElementsColumn2: FormElement[] = [
            {
                type: "informations",
                label: "Ancien email",
                modificators: "-d-block",
                oldValue: props.campagneModificationCoordonnee && props.campagneModificationCoordonnee.ancienneValeur ? props.campagneModificationCoordonnee.ancienneValeur : "",
            },
        ];

        const formElementsColumn3: FormElement[] = [
            {
                type: "text",
                fieldName: "nouvelle_valeur",

                label: "Nouvel email (par défaut l'ancien email)",
                placeholder: "Nouvel Email",
                required: true,
                showFieldErrorDetail: true,

                disabled: isFieldDisabled(),

                // Par défault, on affiche l'ancienne valeur par défault (peut-être à changer)
                oldValue: props.campagneModificationCoordonnee.ancienneValeur && props.campagneModificationCoordonnee.nouvelleValeur === "" ? props.campagneModificationCoordonnee.ancienneValeur : props.campagneModificationCoordonnee.nouvelleValeur,
            },
        ];

        //Si le type est notaire, on affiche des champs supplémentaires (lié au choix d'affichage)
        if (props.type === 'notaire') {
            //Information si les champs sont désactivés (et modifiable si non désactivé)
            if (isFieldDisabled()) {
                formElementsColumn2.push({
                    type: "informations",
                    label: "Sous-domaine personnalisé choisi par l’office",
                    modificators: "-d-block",
                    oldValue: props.campagneModificationCoordonnee.sousDomainePersonnalise ? props.campagneModificationCoordonnee.sousDomainePersonnalise : "",
                });
                formElementsColumn3.push({
                    type: "informations",
                    label: "Choix d’affichage",
                    modificators: "-d-block",
                    oldValue: CampagneModificationCoordonneesConstants.choixAffichageDomaineEmailOptions.find((option) => option.value === props.campagneModificationCoordonnee.choixAffichageDomaineEmail)?.label ?? '',
                });
            } else {
                formElementsColumn2.push(
                    {
                        type: "text",
                        fieldName: "sous_domaine_personnalise",
                        label: "Sous-domaine personnalisé choisi par l’office",
                        placeholder: "Ex : @denomination-office.notaires.fr",
                        required: true,
                        showFieldErrorDetail: true,
                        oldValue: props.campagneModificationCoordonnee.sousDomainePersonnalise ? props.campagneModificationCoordonnee.sousDomainePersonnalise : "",
                    },
                );
                formElementsColumn3.push(
                    {
                        type: "select",
                        fieldName: "choix_affichage_domaine_email",
                        label: "Choix d’affichage",
                        placeholder: "Sélectionnez un choix d'affichage",
                        modificators: "-on-white",
                        hideSearchOnMultiple: true,
                        oldValue: props.campagneModificationCoordonnee.choixAffichageDomaineEmail,
                        options: CampagneModificationCoordonneesConstants.choixAffichageDomaineEmailOptions,
                        required: true,
                        hideEmptyOption: true,
                        showFieldErrorDetail: true,
                    },
                );
            }
        }

        const currentFormColumns: FormColumn[] = [];

        //Ajout d'un champ caché pour le type (notaire ou office)
        formElementsColumn1.push({
            type: "hidden",
            fieldName: "type",
            modificators: '-hidden',
            required: false,
            oldValue: props.type,
        });

        if (formElementsColumn1.length > 0) {
            currentFormColumns.push({
                elements: formElementsColumn1
            });
        }

        currentFormColumns.push({
            elements: formElementsColumn2
        });
        currentFormColumns.push({
            elements: formElementsColumn3
        });
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaire
        const currentFormActions: FormActions[] = [];

        if (!finishedStatuses.includes(props.campagneModificationCoordonnee.etat) && props.mode !== 'readonly') {
            //Si on n'est pas à la fin du process,
            // => On affiche l'enregistrement de la nouvelle coordonnée
            currentFormActions.push({
                label: "Enregistrer",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: false,
                onAction: (formData) => {
                    onSaveNouvelDonnee(formData);
                }
            });

            if (props.campagneModificationCoordonnee.etat === "a-traiter") {
                //Si on est à l'état "a-traiter"
                // => Permettre de faire une demande de suppression de la coordonnée (rattachée à la campagne de modification de coordonnée)
                currentFormActions.push({
                    label: "Demander la suppression",
                    modificators: "-primary-border-only",
                    icon: "icon-remove-alternative",
                    hasLoading: false,
                    onAction: () => {
                        setShowConfirmation(true);
                        setOnConfirm(() => onSetStateToDelete);
                        setConfirmationText("Souhaitez-vous vraiment demander la suppression de cette coordonnée ?");
                    }
                });
            }
        } else {
            // Si la campagne est en attente de validation par la chambre, on affiche le bouton de validation (si l'utilisateur est connecté au progiciel et est admin)
            if (props.campagneModificationCoordonnee.etat === "a-valider-par-chambre" && isAdmin && isAuthenticated && props.mode !== 'readonly') {
                //Afficher un bouton pour Enregistrer la nouvelle donnée (si besoin)
                currentFormActions.push({
                    label: "Enregistrer",
                    modificators: "-primary",
                    icon: "icon-sauvegarde",
                    hasLoading: false,
                    onAction: (formData) => {
                        onSaveNouvelDonnee(formData);
                    }
                });

                //Afficher un bouton pour Valider la coordonnées
                currentFormActions.push({
                    label: "Valider",
                    modificators: "-primary",
                    icon: "icon-check",
                    hasLoading: false,
                    onAction: () => {
                        onValidateCampagneModificationCoordonnee();
                    }
                });
            }
        }

        setFormActions(currentFormActions);

    }, [props.campagneModificationCoordonnee]);

    const isFieldDisabled = (): boolean => {
        let disabledNouvelleValeur: boolean = false;

        //Si la campagne est passé dans un des états finaux, on ne peut plus modifier la coordonnée (pour les non-admin)
        if (finishedStatuses.includes(props.campagneModificationCoordonnee.etat) && !isAuthenticated) {
            disabledNouvelleValeur = true;
        } else if (adminFinishedStatuses.includes(props.campagneModificationCoordonnee.etat) && (isAuthenticated && isAdmin)) {
            //Si la campagne est passé dans un des états finaux (admin), on ne peut plus modifier la coordonnée (pour les admin)
            disabledNouvelleValeur = true;
        }
        return disabledNouvelleValeur;
    }

    /**
     * Soumission du formulaire (pour l'enregistrement de la nouvelle donnée)
     *
     * @param {FormComponentFormData} formData
     */
    const onSaveNouvelDonnee = (formData: FormComponentFormData) => {
        if (!props.submitMethod) return;

        if (props.campagneModificationCoordonnee && props.campagneModificationCoordonnee.id) {
            props.submitMethod(formData as ICampagneModificationCoordonneeFormData).then((response: ICampagnesModificationsGetApiResponse) => {
                //On reset les erreurs
                setErrorsMessage(null);
                setErrorFieldsMessages(null);

                if (props.onSuccessSubmit) {
                    props.onSuccessSubmit(response);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: response.messages[0],
                    type: "success"
                });

            }, (error: IApiCustomResponse) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                if (!error.messages && !error.messages) {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: "Une erreur est survenue lors de la modification des coordonnées.",
                        type: "danger"
                    });
                }
            });
        } else {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la modification des coordonnées.",
                type: "danger"
            });
        }
    }

    /**
     * Bouton pour la validation de la campagne de modification de la coordonnée
     */
    const onValidateCampagneModificationCoordonnee = () => {
        //On reset les erreurs
        setErrorsMessage(null);
        setErrorFieldsMessages(null);

        campagneModificationCoordonneeService.validation(props.campagneModificationCoordonnee.id)
            .then((response: ICampagnesModificationsGetApiResponse) => {
                if (response) {
                    if (props.onSuccessSubmit) {
                        props.onSuccessSubmit(response);
                    }

                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "success"
                    });
                } else {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "danger"
                    });
                }
            })
            .catch((error) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la validation de la campagne de modification de coordonnée.",
                    type: "danger"
                });
            });
    }

    /**
     * Bouton pour la demande de suppression de la coordonnée rattachée à la campagne de modification de coordonnée
     */
    const onSetStateToDelete = () => {
        //On reset les erreurs
        setErrorsMessage(null);
        setErrorFieldsMessages(null);
        onCancelConfirmation();

        campagneModificationCoordonneeService.setStateToDelete(props.campagneModificationCoordonnee.id)
            .then((response: ICampagnesModificationsGetApiResponse) => {
                if (response) {
                    if (props.onSuccessSubmit) {
                        props.onSuccessSubmit(response);
                    }

                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "success"
                    });
                } else {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "danger"
                    });
                }
            })
            .catch((error) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la demande de suppression de la coordonnée.",
                    type: "danger"
                });
            });
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
        setOnConfirm(null);
        setConfirmationText(null);
    }

    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent
                    onConfirm={onConfirm}
                    onCancel={onCancelConfirmation}
                    text={confirmationText}
                    confirmText="Supprimer"/>
            }
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions="-mt-20"
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}
            />
        </>
    )
}
