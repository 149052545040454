import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import useCourrier from "src/Hooks/UseCourrier";
import {ICourrier} from "../../Models/Courrier.model";
import DateHelper from "../../Helpers/Date.helper";
import AccordionSimpleComponent from "../../Components/Accordions/AccordionSimple.component";
import CourriersHelper from "../../Helpers/Courriers.helper";
import ReactHtmlParser from "react-html-parser";


type ICourriersFicheScreenProps = {
    cid: string
}
export default function CourriersFicheScreen(props: ICourriersFicheScreenProps) {
    const {getCourrierById} = useCourrier();
    const [courrier, setCourrier] = useState<ICourrier>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [title, setTitle] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        if (!courrier) return;

        let titleTmp = courrier.sujet ? courrier.sujet : courrier.courrierType ? courrier.courrierType.sujet : "-";
        setTitle(titleTmp);

        const breadcrumbLinks = [
            {text: "Courriers", link: AppConstants.pathCourriers},
            {text: titleTmp},
        ];
        setBreadcrumbLinks(breadcrumbLinks);

    }, [courrier, loaded])


    useEffect(() => {
        if (!props.cid) {
            history.push(AppConstants.pathCourriers);
        }

        getCourrier();

    }, [props.cid])


    /**
     * Récupération du courrier selon l'id
     */
    const getCourrier = (): void => {
        getCourrierById(props.cid, (courrier: ICourrier) => {
            console.log(courrier);
            setCourrier(courrier);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={`Courriers -  ${title}`}
                                         icon="icon-courriers" modificators="" onSearch={null}/>

                    <TabsContainerComponent onClickTabCallback={() => {
                    }}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionSimpleComponent
                                    title="Informations"
                                    icon={"icon-informations"}
                                    contentShowedOnInit={true}>
                                    <div className="informations-wrapper">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Module
                                                    </p>
                                                    <p className="value">
                                                        {CourriersHelper.getModuleForCourrierType(courrier.courrierType) ?? "-"}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Etat (actuel)
                                                    </p>
                                                    <p className="value">
                                                        <span
                                                            className={`state-bullet icon-bullet -${courrier.mailjetCurrentStatus ?? 'empty'}`}>&nbsp;</span>
                                                        <span>
                                                            {courrier.mailjetCurrentStatus ? courrier.mailjetCurrentStatusLabel : "-"}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Sujet
                                                    </p>
                                                    <p className="value">
                                                        {title}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Email (destinataire)
                                                    </p>
                                                    <p className="value">
                                                        {courrier.email ?? "-"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Date d'envoi
                                                    </p>
                                                    <p className="value">
                                                        {DateHelper.getFormatedDate(courrier.dateEnvoi, true)}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">
                                                        Id Mailjet
                                                    </p>
                                                    <p className="value">
                                                        {courrier.mailjetMessageId ?? "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSimpleComponent>

                                <AccordionSimpleComponent
                                    title="Contenu du mail"
                                    icon={"icon-courriers"}
                                    contentShowedOnInit={false}>
                                    <div className="informations-text">
                                            {courrier.contenuMail &&
                                                ReactHtmlParser(courrier.contenuMail)
                                            }
                                    </div>
                                </AccordionSimpleComponent>

                                <AccordionSimpleComponent
                                    title="Evénements Mailjet"
                                    icon={"icon-description"}
                                    contentShowedOnInit={false}>
                                    <div className="informations-text">
                                            {courrier.mailjetEvents &&
                                                courrier.mailjetEvents.map((event, index) => {
                                                    return <div key={index}>{JSON.stringify(event)}</div>
                                                })
                                            }
                                    </div>
                                </AccordionSimpleComponent>
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
