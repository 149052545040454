
export type Module = {
    name: string,
    slug: string,
    icon: string,
    submodules: {link: string, name: string, icon: string, external?: boolean}[],
    link?: string
}

export const modules: Module[] = [
    {
        name: "Accueil",
        slug: "accueil",
        icon: "icon-accueil-light",
        link: "/",
        submodules: []
    },
    {
        name: "Annuaire",
        slug: "annuaire",
        icon: "icon-univers-annuaire",
        submodules: [
            {link: "/annuaire/offices", name: "Offices", icon: "icon-office"},
            {link: "/annuaire/annexes", name: "Annexes", icon: "icon-annexe"},
            {link: "/annuaire/notaires", name: "Notaires", icon: "icon-notaires"},
            {link: "/annuaire/anciens-notaires", name: "Anciens notaires", icon: "icon-notaires"},
            {link: "/annuaire/organismes", name: "Organismes", icon: "icon-organismes"},
            {link: "/annuaire/personnes-exterieures", name: "Personnes Extérieures", icon: "icon-personne-exterieure"},
            {link: "/annuaire/groupes", name: "Groupes", icon: "icon-groupes"},
            {link: "/annuaire/certifications", name: "Certifications", icon: "icon-certifications"},
            {link: "/annuaire/labels", name: "Labels", icon: "icon-labels"},
        ]
    },
    {
        name: "Formations",
        slug: "formations",
        icon: "icon-univers-formations",
        submodules: [
            {link: "/formations", name: "Liste des formations", icon: "icon-univers-formations"},
            {link: "/formations/intervenants", name: "Intervenants", icon: "icon-univers-formations"},
            {link: "/formations/urssaf", name: "Attestation Urssaf", icon: "icon-urssaf"},
            {link: "/formations/urssaf/courrier", name: "Courrier URSSAF", icon: "icon-courrier"},
        ]
    },
    {
        name: "Successions",
        slug: "successions",
        icon: "icon-successions",
        link: "/successions",
        submodules: []
    },
    {
        name: "Tableau de bord",
        slug: "tableau-de-bord",
        icon: "icon-tableau-bord",
        link: "/tableau-de-bord",
        submodules: []
    },
    {
        name: "Sinistres",
        slug: "sinistres",
        icon: "icon-sinistres",
        submodules: [
            {link: "/sinistres", name: "Dossiers/Sinistres", icon: "icon-sinistres"},
            {link: "/ctrcs", name: "CTRC", icon: "icon-ctrcs"},
        ]
    },
    {
        name: "Différends",
        slug: "differends",
        icon: "icon-differends",
        link: "/differends",
        submodules: []
    },
    {
        name: "Réclamations",
        slug: "reclamations",
        icon: "icon-reclamations",
        link: "/reclamations",
        submodules: []
    },
    {
        name: "Inspections",
        slug: "inspections",
        icon: "icon-inspections",
        link: "",
        submodules: [
            {link: "/inspections/habilitations", name: "Habilitations", icon: "icon-habilitations"},
            {link: "/inspections/statistiques", name: "Statistiques", icon: "icon-statistiques"},
            {link: "/inspections/classements", name: "Classements", icon: "icon-classements"},
            {link: "/inspections", name: "Inspections", icon: "icon-inspections"},
        ]
    },
    {
        name: "Cotisations",
        slug: "cotisations",
        icon: "icon-cotisations",
        link: "",
        submodules: [
            {link: "/cotisations/offices", name: "Fiche Office", icon: "icon-cotisation-office"},
            {link: "/cotisations/saisie-notaires", name: "Saisie Notaires", icon: "icon-cotisation-saisie-notaire"},
            {link: "/cotisations/saisie-offices", name: "Saisie Office", icon: "icon-cotisation-saisie-office"},
            {link: "/cotisations/parametres", name: "Paramètres", icon: "icon-cog"},
        ]
    },
    {
        name: "Fil d'infos",
        slug: "fil-dinfos",
        icon: "icon-fil-infos",
        link: "/fil-infos",
        submodules: []
    },
    {
        name: "Correspondances",
        slug: "correspondances",
        icon: "icon-correspondances",
        link: "",
        submodules: [
            {link: "/correspondances/courriers", name: "Courriers", icon: "icon-courrier"},
            {link: "/correspondances/lettres-types", name: "Lettres types", icon: "icon-lettre-type"},
            {link: "/correspondances/signatures", name: "Signatures", icon: "icon-signature"},
            {link: "https://app.ar24.fr/fr/user/login/required", name: "AR24", icon: "icon-paper-plane", external: true},
        ]
    },
    /*
    {
        name: "Courriers",
        slug: "courriers",
        icon: "icon-courriers",
        link: "/courriers",
        submodules: []
    },
     */
    {
        name: "Utilisateurs",
        slug: "utilisateurs",
        icon: "icon-notaires",
        link: "/utilisateurs",
        submodules: []
    },
    {
        name: "Paramètres",
        slug: "parametres",
        icon: "icon-cog",
        link: "/parametres",
        submodules: []
    },

];
