import {IOption} from "src/Components/Fields/Select.field.component";

export type ICampagneModificationCoordonneesConstants = {
    choixAffichageDomaineEmailOptions: IOption[],
    etatsOptions: IOption[],
}
export const CampagneModificationCoordonneesConstants: ICampagneModificationCoordonneesConstants = {
    //Note: En cas de changement, il faut aussi modifier le fichier server/config/campagnesModificationsCoordonnees.php
    choixAffichageDomaineEmailOptions: [
        {
            value: 'sous-domaine-personnalise',
            label: 'Affichage du sous-domaine personnalisé (@denomination-office.notaires.fr)'
        },
        {
            value: 'reecriture-format-notaires-fr',
            label: 'Réécriture sous le format @notaires.fr'
        },
    ],
    //Note : En cas de changement, il faut aussi modifier le fichier server/config/campagnesModificationsCoordonnees.php
    etatsOptions: [
        {
            value: "a-traiter",
            label: "À traiter",
        },
        {
            value: "a-confirmer-par-email",
            label: "À confirmer par email",
        },
        {
            value: "choix-affichage-a-preciser",
            label: "Choix d'affichage et sous-domaine personalisé à préciser",
        },
        {
            value: "a-valider-par-chambre",
            label: "À valider par la Chambre",
        },
        {
            value: "validee-par-chambre",
            label: "Validée par la Chambre",
        },
        {
            value: "a-supprimer-par-chambre",
            label: "À supprimer par la Chambre",
        },
        {
            value: "supprimee-par-chambre",
            label: "Supprimée par la Chambre",
        },
        {
            value: "coordonnee-remplacee",
            label: "Coordonnée remplacée",
        },
    ],
};

//  Valeur definie dans le model en back
// a-traiter, a-confirmer-par-email, a-valider-par-chambre, coordonnee-remplacee
