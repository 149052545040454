import ApiService, {IApiCustomResponse, IApiPagination,} from "./Api.service";
import {ICourrier, ICourrierType} from "../Models/Courrier.model";

export type ICourriersTypesGetApiResponse = IApiCustomResponse & {
    datas: {
        types: ICourrierType[]
    }
}
export type ICourriersTypeGetApiResponse = IApiCustomResponse & {
    datas: {
        type: ICourrierType
    }
}

export type ICourrierGetApiResponse = IApiCustomResponse & {
    datas: {
        courrier: ICourrier
    }
}
export type ICourriersGetPaginationApi = IApiPagination & {
    data: ICourrier[],
}
export type ICourriersGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICourriersGetPaginationApi
    }
}
export type ICourriersGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
}
export class CourriersService extends ApiService{

    /**
     * Permet la récupération des types de courriers pour un modle
     *
     * @returns {Promise<ICourriersTypesGetApiResponse>}
     */
    public getCourriersTypesForModule(module: string, id:number = null): Promise<ICourriersTypesGetApiResponse>{
        return this.doApiCall(`api/${module}${id !== null ? `/${id}` : ''}/courriers/types`, 'GET');
    }

    /**
     * Permet la récupération spécifique d'un courrier pour les formations
     *
     * @param {string} type
     * @param {number} formationId
     * @returns {Promise<ICourriersTypeGetApiResponse>}
     */
    public getFormationsCourrierForPreview(type: string, formationId?: number): Promise<ICourriersTypeGetApiResponse>{
        return this.doApiCall(`api/formations/courriers/types/${type}`, 'GET', {formationId:formationId});
    }

    /**
     * Permet la récupération d'un courrier à partir de son id
     *
     * @param {string} cid
     * @returns {Promise<ICourrierGetApiResponse>}
     */
    public getCourrier(cid: string): Promise<ICourrierGetApiResponse>{
        return this.doApiCall('api/courriers/' + cid + '/', 'GET');
    }

    /**
     * Permet la récupération d'une liste de courriers
     *
     * @returns {Promise<ICourriersGetApiResponse>}
     */
    public getCourriers(formData: ICourriersGetFormData): Promise<ICourriersGetApiResponse>{
        return this.doApiCall('api/courriers', 'GET', formData);
    }
}
