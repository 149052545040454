import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useHistory, useLocation} from "react-router";
import {IApiCustomResponse, IApiPaginationLink} from "src/Services/Api.service";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import LoadingComponent from "../../Components/Loading/Loading.component";
import {ICourrier} from "../../Models/Courrier.model";
import {CourriersService, ICourriersGetApiResponse, ICourriersGetFormData} from "../../Services/Courriers.service";
import {Link} from "react-router-dom";
import DateHelper from "../../Helpers/Date.helper";
import {Module, modules} from "../../Constants/ModulesConstants";
import SelectMultipleFieldComponent from "../../Components/Fields/SelectMultiple.field.component";
import CourriersHelper from "../../Helpers/Courriers.helper";
import DateFieldComponent from "../../Components/Fields/Date.field.component";
import {CourrierConstants} from "../../Constants/CourrierConstants";

type ICourriersListScreenProps = {};

export default function CourriersListScreen(props: ICourriersListScreenProps) {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathCourriers);
    const [courriers, setCourriers] = useState<ICourrier[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    const modulesOptions = modules.map((m: Module) => ({value: m.slug, label: m.name}));
    modulesOptions.push({value: "campagne-modification-coordonnees", label: "Campagne modification coordonnées"});
    modulesOptions.sort((a, b) => a.label.localeCompare(b.label));


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Courriers"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(defaultFilters);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    /**
     * Récupération de la liste des notaires pouvant avoir des attestations urssaf
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const courriersService: CourriersService = new CourriersService();
        courriersService.getCourriers(filters as ICourriersGetFormData).then((response: ICourriersGetApiResponse) => {
            if (response && response.datas.pagination) {

                // Courriers
                const currentList: ICourrier[] = response.datas.pagination.data;
                setCourriers(currentList);

                // Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }


    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Courriers"
                    icon="icon-courriers"
                    onSearch={(keyword => updateFilters({...defaultFilters, keyword}, true))}
                />
                {
                    !loaded &&
                    <LoadingComponent/>
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu" hideTabHeader={true}>
                                <div className="list-content">
                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`}/>
                                        <SelectMultipleFieldComponent
                                            fieldName="courrierTypeModules"
                                            label="Modules"
                                            options={modulesOptions}
                                            hideCurrentValuesOnTop={true}
                                            errors={null}
                                            placeholder="Tous les modules"
                                            hideSearch={true}
                                            modificators="-inline -on-white"
                                            oldValues={filters.courrierTypeModules ? filters.courrierTypeModules as string[] : []}
                                            onChange={(values: string[]) => updateFilters({courrierTypeModules: values})}
                                        />
                                        <SelectMultipleFieldComponent
                                            fieldName="mailjetCurrentStatuses"
                                            label="Statuts (Mailjet)"
                                            options={CourrierConstants.mailjetStatusOptions}
                                            hideCurrentValuesOnTop={true}
                                            errors={null}
                                            placeholder="Tous les statuts"
                                            hideSearch={true}
                                            modificators="-inline -on-white"
                                            oldValues={filters.mailjetCurrentStatuses ? filters.mailjetCurrentStatuses as string[] : []}
                                            onChange={(values: string[]) => updateFilters({mailjetCurrentStatuses: values})}
                                        />
                                        <DateFieldComponent
                                            fieldName="dateEnvoi"
                                            modificators="-inline -on-white -ms-auto"
                                            label="Date d'envoi"
                                            errors={null}
                                            withTime={false}
                                            onChange={(value: string) => updateFilters({dateEnvoi: value})}
                                            placeholder="Toutes les dates"
                                            oldValue={filters.dateEnvoi ? filters.dateEnvoi as string : ""}/>
                                    </div>

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                        <tr>
                                            {[
                                                {slug: "id", label: "ID"},
                                                {slug: "courrierTypeModule", label: "Module"},
                                                {slug: "mailjetCurrentStatus", label: "Statut (Mailjet)"},
                                                {slug: "dateEnvoi", label: "Date d'envoi"},
                                                {slug: "sujet", label: "Sujet"},
                                                {slug: "email", label: "Email"},
                                            ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                <TableHeaderButtonComponent
                                                    direction={filters.orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                    label={item.label} click={(value: string) => setOrder(value)}/>
                                            </th>)}
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            courriers && courriers.map((courrier: ICourrier, index: number) =>
                                                <tr key={index} className="tr"
                                                    onClick={() => history.push(`${AppConstants.pathCourriers}/${courrier.id}`)}>
                                                    <td className="td -bold">
                                                        {courrier.id}
                                                    </td>
                                                    <td className="td">
                                                        {CourriersHelper.getModuleForCourrierType(courrier.courrierType)}
                                                    </td>
                                                    <td className="td ">
                                                        <span
                                                            className={`state-bullet icon-bullet -${courrier.mailjetCurrentStatus ?? 'empty'}`}>&nbsp;</span>
                                                        <span>
                                                            {courrier.mailjetCurrentStatus ? courrier.mailjetCurrentStatusLabel : "-"}
                                                        </span>
                                                    </td>
                                                    <td className="td">
                                                        {courrier.dateEnvoi ? DateHelper.getFormatedDate(courrier.dateEnvoi, true) : ""}
                                                    </td>
                                                    <td className="td">
                                                        {courrier.sujet ? courrier.sujet : courrier.courrierType ? courrier.courrierType.sujet : "-"}
                                                    </td>
                                                    <td className="td">
                                                        {courrier.email ? courrier.email : "-"}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>

                                    </table>


                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>
        </LayoutComponent>
    )
}
