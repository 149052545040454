import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {IApiCustomResponse} from "../../../Services/Api.service";
import LoadingComponent from "../../../Components/Loading/Loading.component";
import {Link} from "react-router-dom";
import AccordionSimpleComponent from "../../../Components/Accordions/AccordionSimple.component";
import {ICampagneModificationCoordonnee} from "../../../Models/CampagneModificationCoordonnee.model";
import useCampagneModificationCoordonnee from "../../../Hooks/UseCampagneModificationCoordonnee";
import CampagneModificationCoordonneeForm
    from "../../../Components/Forms/CampagneModificationCoordonnee/CampagneModificationCoordoneeForm";
import {
    CampagneModificationCoordonneesService, ICampagneModificationCoordonneeMailsGetApiResponse
} from "../../../Services/CampagneModificationCoordonneesService";
import DateHelper from "../../../Helpers/Date.helper";
import CampagneModificationCoordonneeCourrierFormComponent
    from "../../../Components/Forms/CampagneModificationCoordonnee/CampagneModificationCoordonneeCourrierFormComponent";
import {ICourrier} from "../../../Models/Courrier.model";


type CampagneModificationCoordonneeFicheScreenProps = {
    cmcid: string
}


export default function CampagneModificationCoordonneeFicheScreen(props: CampagneModificationCoordonneeFicheScreenProps) {
    const [campagneModificationCoordonnee, setCampagneModificationCoordonnee] = useState<ICampagneModificationCoordonnee>(null);
    const [campagneModificationCoordonneeMails, setCampagneModificationCoordonneeMails] = useState<ICourrier[]>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadedMails, setLoadedMails] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [getCampagneModificationCoordonneeById] = useCampagneModificationCoordonnee();
    const [titre, setTitre] = useState<string>('');

    useEffect(() => {
        if (!campagneModificationCoordonnee) return;

        let textBreadcrumb = campagneModificationCoordonnee.ancienneValeur;
        if (campagneModificationCoordonnee.notaire != null) {
            textBreadcrumb += ' (' + campagneModificationCoordonnee.notaire.nom + ' ' + campagneModificationCoordonnee.notaire.prenom + ')';
        }
        setTitre(textBreadcrumb);

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Campagnes modifications", link: AppConstants.pathOffices},
            {
                text: `Campagne modification : ${campagneModificationCoordonnee.campagneModificationOffice.campagneModification.nom}`,
                link: `/annuaire/campagnes-modifications/${campagneModificationCoordonnee.campagneModificationOffice.campagneModificationId}`
            },
            {text: textBreadcrumb},
        ];

        setBreadcrumbLinks(breadcrumbLinks);
    }, [campagneModificationCoordonnee, loaded]);


    useEffect(() => {
        if (!props.cmcid) {
            history.push(AppConstants.pathOffices);
        }

        getCampagneModificationCoordonnee();
        getCampagneModificationCoordonneeMails();
    }, [props.cmcid]);


    /**
     * Récupération de la campagne de modification de la coordonnée selon le cmcid
     */
    const getCampagneModificationCoordonnee = (): void => {
        getCampagneModificationCoordonneeById(props.cmcid, (campagneModificationCoordonnee: ICampagneModificationCoordonnee) => {
            setCampagneModificationCoordonnee(campagneModificationCoordonnee);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Récupération de tous les mails deja envoyé pour une campagne
     */
    const getCampagneModificationCoordonneeMails = (): void => {
        const campagneModificationCoordonneeService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

        campagneModificationCoordonneeService.getCampagneModificationCoordonneeMails(props.cmcid).then((response: ICampagneModificationCoordonneeMailsGetApiResponse) => {
            if (response && response.datas) {
                let sortedMails = response.datas.campagneModificationCoordonneeMails.sort(function (a, b) {
                    return b.id - a.id;
                });
                setCampagneModificationCoordonneeMails(sortedMails);

                if (!loadedMails) {
                    setLoadedMails(true);
                }
            }

        }, (error: IApiCustomResponse) => {
            console.log(error);
        });
    }

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (): void => {
        getCampagneModificationCoordonnee();
        getCampagneModificationCoordonneeMails();
    };

    return (
        <>
            {!loaded && !loadedMails &&
                <LoadingComponent/>
            }

            {
                loaded && loadedMails &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={`Campagne de modification de la coordonnée : ${titre}`}
                        icon="icon-coordonnees-adresses"
                        onSearch={null}/>

                    <TabsContainerComponent>
                        <TabPanelComponent label="Informations" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionSimpleComponent
                                    title="Informations"
                                    icon={"icon-informations"}
                                    contentShowedOnInit={true}>
                                    <div className="informations-wrapper">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Office</p>
                                                    <p className="value">
                                                        {campagneModificationCoordonnee.campagneModificationOffice.office.nom}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Url d'édition de la coordonnée (envoyée par
                                                        mail)</p>
                                                    <p className="value">
                                                        <Link
                                                            to={AppConstants.pathCampagneModificationCooordonneeExternal.replace(':ctoken', campagneModificationCoordonnee.tokenAcces)}>
                                                            {AppConstants.serverUrl.substring(0, AppConstants.serverUrl.length - 1) + AppConstants.pathCampagneModificationCooordonneeExternal.replace(':ctoken', campagneModificationCoordonnee.tokenAcces)}
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSimpleComponent>

                                <AccordionSimpleComponent
                                    title="Mails"
                                    icon={"icon-courrier"}
                                    contentShowedOnInit={true}
                                >
                                    <div className="informations-wrapper">
                                        {campagneModificationCoordonneeMails.length > 0 ? (
                                            campagneModificationCoordonneeMails.map((mail) => (
                                                <div className="columns" key={mail.id}>
                                                    <div className="column">
                                                        <div className="informations-block">
                                                            <p className="label">Sujet :</p>
                                                            <p className="value">{mail.sujet}</p>
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div className="informations-block">
                                                            <p className="label">Date d'envoi :</p>
                                                            <p className="value">{DateHelper.formatDate(mail.dateEnvoi, "DD/MM/YYYY H:m")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div className="informations-block">
                                                            <p className="label">Statut :</p>
                                                            <p className="value">
                                                                <span className={`state-bullet icon-bullet -${mail.mailjetCurrentStatus ?? 'empty'}`}>&nbsp;</span>
                                                                <span>
                                                                    {mail.mailjetCurrentStatusLabel ?? '-'}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="informations-block">
                                                <p>Aucun mail envoyé pour cette campagne.</p>
                                            </div>
                                        )}
                                    </div>
                                </AccordionSimpleComponent>


                                <AccordionSimpleComponent
                                    title={`Coordonnée ${campagneModificationCoordonnee.notaire ? 'du notaire' : 'de l\'office'}`}
                                    icon="icon-notaires"
                                    contentShowedOnInit={true}>
                                    <div className="campagne-form-coordinate">
                                        <div className="form-wrapper -mb-20">
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="informations-block">
                                                        <p className="label">Etat</p>
                                                        <p className="value -flex">
                                                             <span
                                                                 className={`state-bullet -before-element icon-bullet -${campagneModificationCoordonnee.etat === 'a-confirmer-par-email' || campagneModificationCoordonnee.etat === 'a-valider-par-chambre' || campagneModificationCoordonnee.etat === 'choix-affichage-a-preciser' ? 'a-faire' : campagneModificationCoordonnee.etat === 'coordonnee-remplacee' || campagneModificationCoordonnee.etat === 'validee-par-chambre' ? 'realisee' : ''}`}>&nbsp;</span>
                                                            {campagneModificationCoordonnee.etatLabel}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CampagneModificationCoordonneeForm
                                            campagneModificationCoordonnee={campagneModificationCoordonnee}
                                            type={campagneModificationCoordonnee.notaire ? 'notaire' : 'office'}
                                            mode={"edit"}
                                        />
                                    </div>
                                </AccordionSimpleComponent>
                            </div>
                        </TabPanelComponent>

                        <TabPanelComponent label="Courrier / E-mail" icon="icon-lettre-type" slug="courrier">
                            <CampagneModificationCoordonneeCourrierFormComponent
                                campagneModificationCoordonnee={campagneModificationCoordonnee}
                                campagneModificationCoordonneeCallback={onSuccessSubmitForm}
                            />
                        </TabPanelComponent>
                    </TabsContainerComponent>

                </LayoutComponent>
            }
        </>
    )
}
