import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import {FormationsService, IFormationFormData} from "src/Services/Formations.service";
import useFormation from "src/Hooks/UseFormation";
import {IFormation} from "src/Models/Formation.model";
import FormCreatorComponent, {
    FormColumn,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IOffice} from "src/Models/Office.model";
import {OfficesService} from "src/Services/Offices.service";
import OfficesHelper from "src/Helpers/Offices.helper";
import {NotairesService} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import CommonHelper from "src/Helpers/Common.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import CalendarComponent from "src/Components/Calendar/Calendar.component";
import {IDocument} from "src/Models/Document.model";
import FileHelper from "src/Helpers/File.helper";
import DocumentLink from "src/Components/Documents/DocumentLink.component";
import {toInteger} from "lodash";
import DateHelper from "../../../Helpers/Date.helper";
import PersonnesExterieuresHelper from "../../../Helpers/PersonnesExterieures.helper";


type IFormationInscriptionExternalScreenProps = {
    fid: number
}
export default function FormationInscriptionExternalScreen(props: IFormationInscriptionExternalScreenProps) {

    const officesService: OfficesService = new OfficesService();
    const notairesService: NotairesService = new NotairesService();
    const [getFormationById, getFormationExternalById] = useFormation();
    const [formation, setFormation] = useState<IFormation>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const history = useHistory();
    const formationsService: FormationsService = new FormationsService();
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [devis,setDevis] = useState<IDocument>(null);
    const [programme,setProgramme] = useState<IDocument>(null);
    const [autresDocuments,setAutresDocuments] = useState<IDocument[]>(null);
    const [outdatedFormation, setOutdatedFormation] = useState<boolean>(false);
    const [outdatedLimitResponseFormation, setOutdatedLimitResponseFormation] = useState<boolean>(false);


    useEffect(() => {
        if (!props.fid) {
            history.push(AppConstants.path403);
        }

        getFormation();

    }, [props.fid]);


    useEffect(() => {
        if(!formation) return;

        officesService.getAllOfficesPrincipalesExternal().then((responseOffices) => {
            if (responseOffices.datas?.offices) {
                notairesService.getAllExternal().then((responseNotaires) => {
                    if (responseNotaires.datas?.notaires) {
                        setFormColumns(generateColumns(responseOffices.datas.offices, responseNotaires.datas.notaires));
                    }
                });
            }
        });

    }, [formation]);

    const generateColumns = (offices: IOffice[], notaires: INotaire[]): FormColumn[] => {
        const repeaterFields1: FormElement[] = [
            {
                type: 'select',
                fieldName: "office",
                label: "Office",
                required: true,
                modificators: '-on-white',
                placeholderKeyword: "Saisie CRPCEN",
                options: OfficesHelper.formatListForOptions(offices),
                oldValue: "",
            },
        ];

        if(formation.covoiturage === "oui"){
            repeaterFields1.push({
                type: 'radio',
                fieldName: "coVoiturage",
                label: "Covoiturage",
                required: true,
                modificators: '-on-white',
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            })
            repeaterFields1.push({
                type: 'text',
                fieldName: "coVoiturageVille",
                label: "Ville du covoiturage",
                required: false,
                modificators: '-on-white',
                oldValue: "",
                condition: {
                    fieldName: "coVoiturage",
                    value: "oui"
                }
            })
        }

        if(formation.cocktail === "oui"){
            repeaterFields1.push({
                type: 'radio',
                fieldName: "presenceCocktail",
                label: "Présence au cocktail",
                required: true,
                modificators: '-on-white',
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            })
        }

        if(formation.repas === "oui"){
            repeaterFields1.push({
                type: 'radio',
                fieldName: "presenceRepas",
                label: "Présence au repas",
                required: true,
                modificators: '-on-white',
                options: AppConstants.ouiNonOptions,
                oldValue: "",
            })
        }




        const repeaterFields2: FormElement[] = [];
        offices.forEach((office) => {
            repeaterFields2.push(
                {
                    type: 'select',
                    fieldName: "notaireId",
                    label: "Notaire",
                    required: true,
                    modificators: '-on-white',
                    options: NotairesHelper.formatListForOptions(notaires, office.id),
                    oldValue: "",
                    condition: [
                        {
                            fieldName: "isCollaborateur",
                            value: "non"
                        },
                        {
                            fieldName: "office",
                            value: office.id.toString()
                        }
                    ]
                }
            );
        });


        const repeaterFields3: FormElement[] = [
            {
                type: 'radio',
                fieldName: "isCollaborateur",
                label: "Collaborateur",
                required: false,
                oldValue: "",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
        ];
        repeaterFields3.push(
                {
                    type: 'select',
                    fieldName: "civiliteCourte",
                    label: "Civilite",
                    placeholder: "Civilite",
                    required: true,
                    modificators: "-on-white",
                    options: AppConstants.civiliteCourteOptions,
                    showFieldErrorDetail: true,
                    condition: {
                        fieldName: "isCollaborateur",
                        value: "oui"
                    }
                }
        );
        [
            {fieldName: "nom", label: "Nom"},
            {fieldName: "prenom", label: "Prénom"},
            {fieldName: "email", label: "Email"},
        ].forEach((item) => {
            repeaterFields3.push(
                {
                    type: 'text',
                    fieldName: item.fieldName,
                    label: item.label,
                    required: true,
                    oldValue: "",
                    modificators: '-on-white',
                    showFieldErrorDetail: true,
                    condition: {
                        fieldName: "isCollaborateur",
                        value: "oui"
                    }
                }
            );
        });

        return [
            {
                modificators: "-full",
                elements: [
                    {
                        type: 'repeater',
                        fieldName: "inscriptions",
                        label: "Préinscriptions",
                        oldRepeatableValues: [{}],
                        formColumns: [
                            {
                            elements: repeaterFields1
                            },
                                {
                                elements: repeaterFields2
                            },
                                {
                                elements: repeaterFields3
                            }
                        ],
                        required: false,
                    },
                ]
            }
        ];
    };


    /**
     * Récupération de l'utilisateur selon l'uid
     */
    const getFormation = (): void => {
        getFormationExternalById(props.fid, (formation: IFormation) => {
            setFormation(formation);
            setProgramme(getDocument(formation,"programme"));
            setDevis(getDocument(formation,"devis"));
            setAutresDocuments(getDocuments(formation,"autre"));
            setOutdatedFormation(new Date() > new Date(formation.date))
            setOutdatedLimitResponseFormation(new Date() > new Date(formation.dateLimiteReponse))

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    const onSubmit = (data: IFormationFormData) => {
        formationsService.saveInscriptionsExternes(props.fid, data).then(() => {
            setSubmitted(true);
        }, (error) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        })
    };

    const getDocument = (formation: IFormation, type: "devis"|"programme"|"autre"): IDocument => {
        for(let doc of formation.documents){
            if(type === doc.type){
                return doc;
            }
        }

        return null;
    };

    const getDocuments = (formation: IFormation, type: "devis"|"programme"|"autre"): IDocument[] => {
        const docsList: IDocument[] = [];
        for(let doc of formation.documents){
            if(type === doc.type){
                docsList.push(doc);
            }
        }

        return docsList;
    };

    const formatCout = (cout: string) => {
        if (cout == '0') {
            return 'Gratuit';
        }

        if (isNumber(cout)) {
            return cout +' €';
        }

        return cout;
    }

    const isNumber = (value: any) => {
        return !isNaN(value) &&
            !isNaN(parseFloat(value))
    }

    return (
        <>
            {
                loaded &&
				<LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
				                 contentModificators={`-bg-white -no-flex`}>
					<PageHeaderComponent text="Formation" subText={"Préinscription à une formation"} icon="icon-formations"
					                     modificators={`-in-fullscreen -border-full-screen`}/>

                    {
                        submitted && <p className="informations-header  -big  ">
                            {
                                formation.inscrits.length >= toInteger(formation.nbMaxParticipants) &&
                                <span className="title">
                                    Merci, les préinscriptions sur la liste d'attente ont bien été enregistrées.
                                </span>
                            }
                            {
                                formation.inscrits.length < toInteger(formation.nbMaxParticipants) &&
                                <span className="title">
                                    Merci, les préinscriptions ont bien été enregistrées.
                                </span>
                            }
                        </p>
                    }

                    {outdatedFormation && <p className="informations-header  "><span className="title">
                        Cette formation est terminée, les préinscriptions sont cloturées.
                    </span></p>}
                    {outdatedLimitResponseFormation && <p className="informations-header  "><span className="title">
                        Les préinscriptions sont cloturées pour cette formation.
                    </span></p>}

                    {(!submitted && !outdatedFormation && !outdatedLimitResponseFormation) && <>
	                    <div className="informations-header  ">
                            <h3 className="title">{formation.titre}</h3>
	                    </div>
	                    <div className="informations-wrapper">
		                    <div className="columns">
			                    <div className="column -auto">
				                    <CalendarComponent date1={formation.date} date2={formation.dateFin}/>

                                    {
                                        /*
                                        (formation.ancienneDate && formation.ancienneDate !== formation.date) &&
                                        <p className="mention">
                                            Attention, la date de cette formation a changée.<br/>Auparavant elle était prévue pour le {DateHelper.getFormatedDate(formation.ancienneDate)}.
                                        </p>
                                         */
                                    }
			                    </div>
			                    <div className="column">
				                    <div className="informations-block">
					                    <p className="label">Lieu</p>
					                    <p className="value">{formation.lieu}</p>
				                    </div>
				                    <div className="informations-block">
					                    <p className="label">Horaire</p>
					                    <p className="value">
                                            {formation.date ? DateHelper.getFormatedHour(formation.date) : ""}
                                            {formation.dateFin ? '-' +  DateHelper.getFormatedHour(formation.dateFin) : ""}
					                    </p>
				                    </div>
				                    <div className="informations-block">
					                    <p className="label">Coût</p>
					                    <p className="value">{ formatCout(formation.cout) }</p>
				                    </div>
				                    <div className="informations-block">
					                    <p className="label">Intervenant.e.s</p>
					                    <p className="value">
                                            {formation.intervenantsPersonnesExterieures.map((intervenant,i)=>
                                                <React.Fragment key={i}>
                                                    <span>
                                                        {intervenant.civilite ? PersonnesExterieuresHelper.getFormatedCivilite(intervenant.civilite) + ' ' : ""}{intervenant.prenom} {intervenant.nom}{ intervenant.fonction ? ', ' + intervenant.fonction : ''}
                                                    </span><br/>
                                                </React.Fragment>
                                            )}
					                    </p>
					                    <p className="value">
                                            {formation.intervenantsNotaires.map((intervenant,i)=>
                                                <React.Fragment key={i}>
                                                    <span>
                                                        {intervenant.prenom} {intervenant.nom}
                                                    </span><br/>
                                                </React.Fragment>
                                            )}
					                    </p>
				                    </div>
			                    </div>
			                    <div className="column">
                                    {
                                        formation.covoiturage && formation.covoiturage === "oui" &&
                                            <div className="informations-block">
                                                <p className="label">Possibilité de Co-voiturage</p>
                                                <p className="value">{formation.covoiturage ? CommonHelper.getFormatedOuiNon(formation.covoiturage) : "Non"}</p>
                                            </div>
                                    }
                                    {
                                        formation.repas && formation.repas === "oui" &&
                                            <div className="informations-block">
                                            <p className="label">Repas</p>
                                            <p className="value">
                                                {
                                                    (formation.repas && formation.repas === "oui") &&
                                                    <>
                                                        {
                                                            formation.repasNom &&
                                                            <>Nom: <strong>{formation.repasNom}</strong><br/></>
                                                        }
                                                        {
                                                            formation.repasHeure &&
                                                            <>Heure: <strong>{formation.repasHeure}</strong><br/></>
                                                        }
                                                        {
                                                            formation.repasLieu &&
                                                            <>Lieu: <strong>{formation.repasLieu}</strong><br/></>
                                                        }
                                                        {
                                                            !formation.repasNom && !formation.repasHeure && !formation.repasLieu &&
                                                            <>Oui</>
                                                        }
                                                    </>
                                                }

                                            </p>
                                        </div>
                                    }
                                    {
                                        formation.cocktail && formation.cocktail === "oui" &&
                                            <div className="informations-block">
                                            <p className="label">Cocktail</p>
                                            <p className="value">
                                                {
                                                    (formation.cocktail && formation.cocktail === "oui") &&
                                                    <>
                                                        {
                                                            formation.cocktailNom &&
                                                            <>Nom: <strong>{formation.cocktailNom}</strong><br/></>
                                                        }
                                                        {
                                                            formation.cocktailHeure &&
                                                            <>Heure: <strong>{formation.cocktailHeure}</strong><br/></>
                                                        }
                                                        {
                                                            formation.cocktailLieu &&
                                                            <>Lieu: <strong>{formation.cocktailLieu}</strong><br/></>
                                                        }
                                                        {
                                                           !formation.cocktailNom && !formation.cocktailHeure && !formation.cocktailLieu &&
                                                           <>Oui</>
                                                        }
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    }

                                    {devis && <div className="informations-block">
					                    <p className="label">Devis</p>
					                    <p className="value">
                                            <DocumentLink link={FileHelper.getFileUrl(devis.fichier, 'documents-formations')} icon={"icon-documents"} />
                                        </p>
				                    </div>}
				                    {programme && <div className="informations-block">
                                        <p className="label">Programme</p>
                                        <p className="value">
	                                        <DocumentLink link={FileHelper.getFileUrl(programme.fichier, 'documents-formations')} icon={"icon-documents"} />
                                        </p>
                                    </div>}
                                    {
                                        autresDocuments && (autresDocuments.length > 0) &&
                                        <div className="informations-block">
                                            <p className="label">Autre</p>
                                            {
                                                autresDocuments.map( (doc, indexDoc) =>
                                                    <p className="value" key={indexDoc}>
                                                        <DocumentLink link={FileHelper.getFileUrl(doc.fichier, 'documents-formations')}
                                                                      icon={"icon-documents"}/>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    }
			                    </div>
                            </div>
                        </div>

	                    <div className="informations-header -margin-top">
		                    <h3 className="title -no-margin">Préinscription {formation.inscrits.length >= toInteger(formation.nbMaxParticipants) && <> sur la liste d'attente</>}</h3>
	                    </div>

                        {
                            formation.inscrits.length >= toInteger(formation.nbMaxParticipants) &&
                            <div className="informations-text -margin-bottom">
                                <p>
                                    La formation est complète. Préinscrivez-vous ci-dessous et vous serez automatiquement informé si une place se libérait.
                                </p>
                            </div>
                        }

                        <FormCreatorComponent formColumns={formColumns}
                            formActions={[
                                {
                                    label: "Enregistrer",
                                    modificators: "-secondary",
                                    onAction: onSubmit
                                }
                            ]}
                            errorFieldsMessages={errorFieldsMessages}
                            errorMessages={errorMessages}
                            modificatorsActions="-outside-right"/>

                    </>}
				</LayoutComponent>
            }
        </>
    )
}
