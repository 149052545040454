import ApiService, {IApiCustomResponse, IExportApiResponse, IFileDownloadApiResponse} from "./Api.service";
import { ICampagnesModificationsGetApiResponse } from "./CampagnesModifications.service";
import {
    ICampagneModificationOfficeVerificationFormData
} from "./CampagnesModificationsOffices.service";
import {ICampagneModificationCoordonnee} from "../Models/CampagneModificationCoordonnee.model";
import { IApiPagination } from "./Api.service";
import {ICourrier} from "../Models/Courrier.model";

export type ICampagneModificationCoordonneeFormData = {
    ancienne_valeur?: string,
    notaire_name?: string,
    nouvelle_valeur: string,
}

export type ICampagnesModificationsCoordonneesPaginationGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    etat?: string[],
    campagneModificationId: number,
}

export type ICampagneModificationCoordonneeCourrierFormData = {
    type: string,
    contenu: string,
    isDebug?: boolean,
    isPreview?: boolean,
    campagneModificationId: number,
}

export type ICampagneModificationCoordonneeVerificationFormData = {
    code: string,
}

export type ICampagneModificationCoordonneeGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagneModificationCoordonnee: ICampagneModificationCoordonnee
    }
}

export type ICampagneModificationCoordonneesGetApiResponse = IApiPagination & {
    data: ICampagneModificationCoordonnee[],
}

export type ICampagnesModificationsCoordonneesGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[]
    }
}

export type ICampagnesModificationsCoordonneesPaginationGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICampagneModificationCoordonneesGetApiResponse
    }
}

export type ICampagneModificationCoordonneeMailsGetApiResponse = IApiCustomResponse & {
    datas: {
        campagneModificationCoordonneeMails: ICourrier[]
    }
}

export class CampagneModificationCoordonneesService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-coordonnees';
    private baseRouteExternal = 'api/external/campagnes-modifications-coordonnees';

    /**
    * Permet la récupération d'une liste des campagnes de modifications des offices avec pagination
    * avec un identifiant de campagne de modification
    *
    * @returns {Promise<IGetUserApiResponse>}
    */
    public getCampagnesModificationsCoordonnesWithPagination(formData: ICampagnesModificationsCoordonneesPaginationGetFormData): Promise<ICampagnesModificationsCoordonneesPaginationGetApiResponse>{
        return this.doApiCall(`${this.baseRoute}`, 'GET', formData);
    }

    /**
     * Permet la mise a jour d'une campagne de modification de coordonnée
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public update(id: number, data: ICampagneModificationCoordonneeFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/update/${id}`, 'POST', data);
    }

    /**
     * Permet de passer une campagne de modification de coordonnée en statut/etat "a-supprimer-par-chambre"
     */
    public setStateToDelete(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/set-state-to-delete/${id}`, 'POST');
    }

    /**
     * Permet de confirmer le changement de coordonnée
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public verification(token: string): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/confirmation/${token}`, 'POST');
    }

    /**
     * Permet de valider le changement de coordonnée (statut: validee-par-chambre)
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public validation(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/validation/${id}`, 'POST');
    }

    /**
     * Permet l'export des campagnes de modifications coordonnées liées à une campagne de modification (excel)
     * @param {string} cmid
     */
    public exportCampagnesModificationsCoordonneesByCampagneModification(cmid: string): Promise<IExportApiResponse>{
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/export-par-campagne-modification/${cmid}`, 'POST');
    }

    /**
     * Permet de récupérer la campagne de modifications coordonnée liée à un id
     *
     * @param cmcid
     */
    public getCampagneModificationCoordonneeById(cmcid: string): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/${cmcid}`, 'GET');
    }

    //////////////////////////////////////////

    public getCampagneModificationCoordonneeByToken(token: string): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}`, 'GET');
    }

    /**
     * Permet de récupérer les campagnes de modifications coordonnées liées à un token
     * dans tous les cas, c'est un tableau même s'il n'y a qu'un seul élément
     * Ex avec plusieurs campagnes : un notaire qui a plusieurs coordonnées (principal, convocation, etc.)
     *
     * Même s'il y a un seul élément, l'API retourne un tableau
     *
     * @param token
     */
    public getCampagnesModificationsCoordonneesByToken(token: string): Promise<ICampagnesModificationsCoordonneesGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}`, 'GET');
    }

    public sendCode(token: string): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/send-code`, 'POST');
    }

    public checkCode(token: string, formDatas: ICampagneModificationOfficeVerificationFormData): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/check-code`, 'POST', formDatas);
    }

    /**
     * Envoi d'un courrier pour une campagne de modification
     *
     * @param {number} cmid
     * @param {ICampagneModificationCoordonneeCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public sendCourrier(cmid: number, formDatas: ICampagneModificationCoordonneeCourrierFormData): Promise<IApiCustomResponse>{
        return this.doApiCall(`${this.baseRoute}/${cmid}/courrier/send`, 'POST', formDatas);
    }

    /**
     * Récupération des courriers deja envoyé pour une campagne
     *
     * @param {number} cmid
     * @returns {Promise<IApiCustomResponse>}
     */
    public getCampagneModificationCoordonneeMails(cmid: string): Promise<ICampagneModificationCoordonneeMailsGetApiResponse>{
        return this.doApiCall(`${this.baseRoute}/${cmid}/courriers`, 'GET');
    }

    /**
     * Téléchargement d'un courrier d'une campagne de modification
     *
     * @param {number} cid
     * @param {ICampagneModificationCoordonneeCourrierFormData} formDatas
     * @returns {Promise<IApiCustomResponse>}
     */
    public downloadCourrier(cmid: number, formDatas: ICampagneModificationCoordonneeCourrierFormData): Promise<IFileDownloadApiResponse>{
        return this.doApiCall(`${this.baseRoute}/${cmid}/courrier/download`, 'POST', formDatas);
    }
}
