import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useContext, useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {ICampagneModificationOffice} from "../../Models/CampagneModificationOffice.model";
import ConfirmationComponent from "../Confirmation/Confirmation.component";
import {IUserContext, UserContext} from "../../Providers/User.provider";


type IAsideCampagnesModificationsOfficesComponentProps = {
    campagneModificationOffice?: ICampagneModificationOffice,
    modificators?: string,
    type: "list" | "fiche",

    exportOfficesCallback?: () => void,
    validationEtatFinalCallback?: () => void,
    replaceCoordonneesCallback?: () => void,
    rollbackCoordonneesCallback?: () => void,

    activeTab?: string,
    filters?: any
}

export default function AsideCampagnesModificationsOfficesComponent(props: IAsideCampagnesModificationsOfficesComponentProps) {
    const [printScreen] = usePrint();
    const {authUser} = useContext(UserContext) as IUserContext;

    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [onConfirm, setOnConfirm] = useState<() => void>();
    const [confirmationText, setConfirmationText] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        let actions: ListAsideAction[] = [];

        if (props.type === "list") {
            actions.push({icon: "icon-print", click: () => printScreen()});
            actions.push({
                icon: "icon-export",
                title: "Exporter les données des offices",
                click: () => props.exportOfficesCallback()
            });
        }

        if (props.type === 'fiche') {
            if (props.campagneModificationOffice.etat === "envoyee") {
                setOnConfirm(() => props.validationEtatFinalCallback);
                setConfirmationText("Souhaitez-vous vraiment passer toutes les coordonnées de cet office en validé ?")
                actions.push(
                    {
                        icon: "icon-check",
                        title: "Passer tous les états des coordonnées en validé",
                        click: () => setShowConfirmation(true)
                    });
            } else {
                //Note IMPORTANT : pour l'instant visible que pour le compte Admin Casus-Belli
                if (authUser.email === 'admin@casusbelli.fr') {
                    //todo mettre pour la Chambre ?
                    if (props.campagneModificationOffice.etat === "validee-par-chambre") {
                        setOnConfirm(() => props.replaceCoordonneesCallback);
                        setConfirmationText("Souhaitez-vous vraiment remplacer les nouvelles coordonnées de cet office, ses notaires et négociateurs ?");
                        actions.push(
                            {
                                icon: "icon-a-venir",
                                title: "Remplacer les coordonnées (nouvelles valeurs)",
                                click: () => setShowConfirmation(true)
                            });
                    } else if (props.campagneModificationOffice.etat === "office-coordonnees-remplacees") {
                        setOnConfirm(() => props.rollbackCoordonneesCallback);
                        setConfirmationText("Souhaitez-vous vraiment remettre les anciennes coordonnées de cet office, ses notaires et négociateurs ?");
                        actions.push(
                            {
                                icon: "icon-termine",
                                title: "Revenir en arrière pour les coordonnées (anciennes valeurs)",
                                click: () => setShowConfirmation(true)
                            });
                    }
                }
            }
        }

        setActions(actions);
    }, [props.type, props.filters]);

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    return (
        <>

            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onConfirm}
                                       onCancel={onCancelConfirmation}
                                       text={confirmationText}/>
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
