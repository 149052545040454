import {IOption} from "src/Components/Fields/Select.field.component";

export type ICourrierConstants = {
    mailjetStatusOptions: IOption[],
}
export const CourrierConstants: ICourrierConstants = {
    mailjetStatusOptions: [
        {
            value: "init-sent",
            label: "Envoyé"
        },
        {
            value: "open",
            label: "Ouvert"
        },
        {
            value: "click",
            label: "Cliqué"
        },
        {
            value: "bounce",
            label: "Rebond"
        },
        {
            value: "spam",
            label: "Spam"
        },
        {
            value: "blocked",
            label: "Bloqué"
        }
    ],
};
