import React from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {IInspection} from "src/Models/Inspection.model";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "../../../Helpers/Date.helper";
import SelectMultipleFieldComponent from "../../Fields/SelectMultiple.field.component";
import {OfficesConstants} from "../../../Constants/OfficesConstants";
import {useHistory} from "react-router";
import SelectFieldComponent from "../../Fields/Select.field.component";
import InspectionsHelper from "../../../Helpers/Inspections.helper";
import {InspectionsConstants} from "../../../Constants/InspectionsConstants";


type IInspectionListSuiviTableProps = {
    inspections: IInspection[],
    filters?: IFilterData,
    setOrder?: (value: string) => void,
    updateFilters?: (data: IFilterData) => void,
    anneeDefaut?: string,
}

export default function InspectionListSuiviTable(props: IInspectionListSuiviTableProps) {
    const history = useHistory();

    return (
        <>
            <div className="list-filters">
                {/* Afficher l'année : celle sélectionnée ou celle par défaut */}
                {
                    (props.filters.annee != null || props.anneeDefaut != null) &&
                    <span className={`year`}>Année {props.filters.annee ? props.filters.annee : props.anneeDefaut != null ? props.anneeDefaut : ''}</span>
                }

                <i className={`icon icon-filtre`}/>

                <SelectFieldComponent
                    fieldName="annee"
                    label="Année"
                    options={InspectionsHelper.getListInspectionsAnneesOptions().reverse()}
                    hideSearch={true}
                    errors={null}
                    oldValue={props.filters.annee ? props.filters.annee as string : props.anneeDefaut}
                    modificators={`-inline -on-white`}
                    onChange={value => props.updateFilters({annee: value})}/>

                <SelectMultipleFieldComponent
                    fieldName="departement"
                    label="Départements"
                    options={OfficesConstants.departementsOptions}
                    hideCurrentValuesOnTop={true}
                    errors={null}
                    placeholder="Tous les départements"
                    hideSearch={true}
                    modificators={`-inline -on-white`}
                    oldValues={props.filters.departement ? props.filters.departement as string[] : []}
                    onChange={(values: string[]) => props.updateFilters({departement: values})} />

                <SelectMultipleFieldComponent
                    fieldName="etat"
                    label="Etat"
                    options={InspectionsConstants.etatsOptions}
                    hideCurrentValuesOnTop={true}
                    errors={null}
                    placeholder="Tous les états"
                    hideSearch={true}
                    modificators={`-inline -on-white`}
                    oldValues={props.filters.etat ? props.filters.etat as string[] : []}
                    onChange={(values: string[]) => props.updateFilters({etat: values})}/>
            </div>
            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {[
                        {slug: "CRPCEN", label: "CRPCEN"},
                        {slug: "nomOffice", label: "Office"},
                        {slug: "dateLettreMission", label: "Envoi de lettre de mission"},
                        {slug: "dateArreteCaisse", label: "Date de l’arrêté de caisse"},
                        {slug: "dateInspection", label: "Date de l’inspection"},
                        {slug: "dateEnvoiRapportProcureur", label: "Date de l’envoi du rapport au procureur"},
                        {slug: "dateAvisMotive", label: "Date de l’avis motivé"},
                    ].map((item, iIndex) => <th className="th" key={iIndex}>
                        <TableHeaderButtonComponent
                            direction={props.filters.orderType as string || AppConstants.orderType.asc}
                            value={item.slug} selectedValue={props.filters.orderBy as string || ""}
                            label={item.label}
                            click={props.setOrder ? (value: string) => props.setOrder(value) : null}/>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {
                    props.inspections && props.inspections.map((inspection: IInspection, index: number) =>
                        <tr key={index} onClick={() => history.push(`${AppConstants.pathInspections}/${inspection.id}`)}
                            className="tr">
                            <td className="td">
                                {inspection.office.CRPCEN}
                            </td>
                            <td className="td">
                                {inspection.office.nom}
                            </td>
                            <td className="td">
                                {
                                    inspection.dateLettreMission ? DateHelper.getFormatedDate(inspection.dateLettreMission) : ""
                                }
                            </td>
                            <td className="td">
                                {
                                    inspection.dateArreteCaisse ? DateHelper.getFormatedDate(inspection.dateArreteCaisse) : ""
                                }
                            </td>
                            <td className="td">
                                {
                                    inspection.dateInspection ? DateHelper.getFormatedDate(inspection.dateInspection) : ""
                                }
                            </td>
                            <td className="td">
                                {
                                    inspection.dateEnvoiRapportProcureur ? DateHelper.getFormatedDate(inspection.dateEnvoiRapportProcureur) : ""
                                }
                            </td>
                            <td className="td">
                                {
                                    inspection.dateAvisMotive ? DateHelper.getFormatedDate(inspection.dateAvisMotive) : ""
                                }
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </>
    );
}
