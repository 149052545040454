import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {IFormField} from "./Input.field.component";
import {IOption, IOptionsGroup} from "./Select.field.component";
import LabelComponent from "./Label.component";
import CheckboxFieldComponent from "./Checkbox.field.component";
import StringHelper from "src/Helpers/String.helper";
import useClickOutside from "src/Hooks/useClickOutside";
import {IApiErrorMessage} from "../../Services/Api.service";
import ErrorFieldsMessagesComponent from "../Errors/ErrorFieldsMessages.component";

type SelectMultipleFieldComponentProps = IFormField & {
    hideSearch?: boolean,
    hideCurrentValuesOnTop?: boolean,
    hideCheckAllGroupOptions?: boolean,
    shouldAddClassWithCountToParent?: boolean,
    options?: IOption[],
    optionsGroup?: IOptionsGroup[],
    oldValues?: string[],
    oldValuesReadOnly?: string[],
    placeholderKeyword?: string,
    onChange?: (values: string[]) => void,
    showFieldErrorDetail?: boolean,
}

export default function SelectMultipleFieldComponent(props: SelectMultipleFieldComponentProps){
    const [currentValues, setCurrentValues] = useState<string[]>([]);
    const [selectedLabel, setSelectedLabel] = useState<string>(props.placeholder || "");
    const [selectedLabelInput, setSelectedLabelInput] = useState<string>(props.placeholder || "");
    const [dropdownShowed, setDropdownShowed] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>("");
    const [filteredOptions, setFilteredOptions] = useState<IOption[]>(props.options || []);
    const [filteredOptionsGroup, setFilteredOptionsGroup] = useState<IOptionsGroup[]>(props.optionsGroup || []);
    const ref = useRef<HTMLDivElement>();
    useClickOutside(ref,()=>setDropdownShowed(false));
    const [errors, setErrors] = useState<IApiErrorMessage>(null);


    useEffect(()=> {
        setCurrentValues(Array.isArray(props.oldValues) ? props.oldValues : [props.oldValues]);
    }, [props.oldValues]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);


    /**
     * Génération des listes d'option
     * Si le keyword est pas vide, on filtre les options dispnibles
     * Sinon on prend toutes les options disponibles
     */
    useEffect(() => {
        const stringHelper = new StringHelper();
        let newFilteredOptions: IOption[] = [];
        let newFilteredOptionsGroup: IOptionsGroup[] = [];

        if( keyword && keyword.length) {
            const keywordCleaned = stringHelper.cleanString(keyword);

            if (props.options && props.options.length) {
                for (let option of props.options) {
                    const labelCleaned = stringHelper.cleanString(option.label);
                    const optionKeywords: string[] = option.keywords || [];

                    if( labelCleaned.includes(keywordCleaned)){
                        newFilteredOptions.push(option);
                    }
                    else {
                        optionKeywords.forEach((k: string) => {
                            if(newFilteredOptions.includes(option)) return;

                            const kCleaned = stringHelper.cleanString(k);

                            if (kCleaned.includes(keywordCleaned)) {
                                newFilteredOptions.push(option);
                            }
                        });
                    }
                }
            }

            if (props.optionsGroup && props.optionsGroup.length) {
                for (let group of props.optionsGroup) {
                    const optionsList: IOption[] = [];

                    for (let option of group.options) {
                        const labelCleaned = stringHelper.cleanString(option.label);
                        const optionKeywords: string[] = option.keywords || [];

                        if( labelCleaned.includes(keywordCleaned)){
                            optionsList.push(option);
                        }
                        else {
                            optionKeywords.forEach((k: string) => {
                                if(optionsList.includes(option)) return;

                                const kCleaned = stringHelper.cleanString(k);

                                if (kCleaned.includes(keywordCleaned)) {
                                    optionsList.push(option);
                                }
                            });
                        }
                    }
                    if( optionsList && optionsList.length){
                        group.options = optionsList;
                        newFilteredOptionsGroup.push(group);
                    }
                }
            }
        }
        else{
            newFilteredOptions = props.options || [];
            newFilteredOptionsGroup = props.optionsGroup || [];
        }

        setFilteredOptions(newFilteredOptions);
        setFilteredOptionsGroup(newFilteredOptionsGroup);
    }, [props.options, props.optionsGroup, keyword]);



    /**
     * On ecouté le changement des currentValues
     * Afin de générer un label pour le faux select
     *
     */
    useEffect(() => {
        generateSelectedLabel();
    }, [currentValues,props.options, props.optionsGroup]);



    /**
     * Ecoute du changement du la checkbox
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
        const isNewChecked: boolean = e.target.checked;
        const value: string = e.target.value;
        const currentValuesUpdated: string[] = currentValues;

        //On ajoute la valeur si la case est cochée et si la valeur n'est pas déjà ajoutée
        if( isNewChecked && !checkIsChecked(value)  ){
            currentValuesUpdated.push(value);
        }

        //On supprime la valeur du tableau si la case n'est pas cochée
        if( !isNewChecked && checkIsChecked(value) ){

            const index: number = currentValues.findIndex((v: string) => v === value);
            currentValuesUpdated.splice(index, 1);
        }

        setCurrentValues(currentValuesUpdated);

        generateSelectedLabel();


        //on ajoute un attribut au parent pour décaler les columns a côté
        if(props.shouldAddClassWithCountToParent) {
            const parent = e.currentTarget.closest('.column');
            let currentDataCount = currentValues.length
            parent.setAttribute('data-count-child-select-value', currentDataCount.toString());

        }


        //On envoi les valeurs au parent
        if(props.onChange) {
            props.onChange(currentValues);
        }

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    }


    /**
     * Gestion du check des group d'options
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {IOptionsGroup} group
     */
    const onChangeCheckboxGroup = (e: ChangeEvent<HTMLInputElement>, group: IOptionsGroup): void => {
        const isGroupChecked: boolean = e.target.checked;
        group.isChecked = isGroupChecked;
        const currentValuesUpdated: string[] = currentValues;

        for(let option of group.options){
            const value: string = option.value;

            //Si le groupe est coché et l'option non coché on l'ajoute à la liste
            if(isGroupChecked && !checkIsChecked(value)){
                currentValuesUpdated.push(value);
            }
            //Si le groupe n'est pas checké et l'option coché, on la supprime de la liste
            else if(!isGroupChecked && checkIsChecked(value)){
                const index: number = currentValues.findIndex((v: string) => v === value);
                currentValuesUpdated.splice(index, 1);
            }
        }

        setCurrentValues(currentValuesUpdated);

        generateSelectedLabel();

        //On envoi les valeurs au parent
        if(props.onChange) {
            props.onChange(currentValues);
        }

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    }


    /**
     * Vérifie si une valeur a été cochée
     *
     * @param {string} value
     * @returns {boolean}
     */
    const checkIsChecked = (value: string): boolean => {
        const isChecked: boolean = currentValues.includes(value);
        return isChecked;
    };

    /**
     * Ecoute du changement de mot clé
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onKeywordChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setKeyword(e.target.value);
    };

    /**
     * Permet la génération du label contenant les label des options choisis
     * Affiché dans le faux select
     */
    const generateSelectedLabel = (): void => {
        let label: string = "";
        let labelInput: string = "";

        if(currentValues){
            const labels: string[] = [];

            if (props.options && props.options.length) {
                for(let value of currentValues){
                    for (let option of props.options) {
                        if( value == option.value.toString()){
                            labels.push(option.label);
                        }
                    }
                }

            }
            if (props.optionsGroup && props.optionsGroup.length) {
                for(let value of currentValues){
                    for (let group of props.optionsGroup) {
                        for (let option of group.options) {
                            if( value == option.value.toString()){
                                labels.push(option.label);
                            }
                        }
                    }
                }
            }

            label = labels.join(', ');
            labelInput = labels.join(', ');
        }

        if( !label.length){
            label = props.placeholder || "";
        }

        setSelectedLabel(label);
        setSelectedLabelInput(labelInput);
    };


    /**
     * Permet la suppression d'une seule valeur dans le tableau de valeur choisie
     *
     * @param {string} valueToDelete
     */
    const removeCurrentValue = (valueToDelete: string):void => {
        const values: string[] = currentValues.filter((value: string) => value != valueToDelete);
        setCurrentValues(values);

        //On envoi les valeurs au parent
        if(props.onChange) {
            props.onChange(values);
        }
    }



    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            {
                !props.hideCurrentValuesOnTop && props.oldValuesReadOnly && props.oldValuesReadOnly.length > 0 &&
                <div className="select-current-values">
                    {
                        props.oldValuesReadOnly.map((value, index) =>
                            <p className="line" key={index}>
                                <span className="value">{value}</span>
                            </p>
                        )
                    }
                </div>
            }
            {
                !props.hideCurrentValuesOnTop && currentValues && currentValues.length > 0 && currentValues.map &&
                <div className="select-current-values">
                    {
                        currentValues.map((value, index) =>
                            <p className="line" key={index}>
                                <button className="remove icon-remove" onClick={() => removeCurrentValue(value)} />
                                <span className="value">{selectedLabel.split(', ')[index]}</span>
                            </p>
                        )
                    }
                </div>
            }



            <div className={`select-multiple-wrapper ${errors ? '-error' : ''} ${dropdownShowed ? '-opened' : ''}`}>

                <div className={`select-line ${dropdownShowed ? '-options-showed' : ""}`} onClick={() => setDropdownShowed(!dropdownShowed)} >
                    <div className={`form-field-input`}><div>{ props.hideCurrentValuesOnTop ? selectedLabel: (props.placeholder || selectedLabelInput)}</div></div>
                    <i className="form-field-aside icon-arrow-down-full"  />
                </div>

                {
                    dropdownShowed &&
                        <div className="options-wrapper" ref={ref}>
                            {
                                !props.hideSearch &&
                                    <div className="search-wrapper">
                                        <input type="text" className="form-field-input -full-w" name="search-option" onChange={onKeywordChange} placeholder={props.placeholderKeyword ? props.placeholderKeyword :''} />
                                    </div>
                            }

                            <div className="list">
                                {
                                    filteredOptions && filteredOptions.map( (o: IOption, index: number) =>
                                        <CheckboxFieldComponent value={o.value} id={props.fieldName + '_' + o.value} name={props.fieldName} label={o.label}  onChange={onChangeCheckbox} isChecked={currentValues.includes(o.value.toString())} key={'checkbox_' + index} modificators={index !== filteredOptions.length-1 ? '-with-border-bottom' : '' } />
                                    )
                                }
                                {
                                    filteredOptionsGroup && filteredOptionsGroup.map( (g: IOptionsGroup, index: number) =>
                                        <div className="form-field-group" key={index}>
                                            <div className="group-title">
                                                {
                                                    props.hideCheckAllGroupOptions &&
                                                    <LabelComponent fieldName={props.fieldName + "_group_" + index} label={g.label} modificators={`-no-margin-b -fw-medium -got-hover -group ${props.labelModificators || "-size-2"}`} />
                                                }
                                                {
                                                    !props.hideCheckAllGroupOptions &&
                                                    <CheckboxFieldComponent value={props.fieldName + "_group_" + index} id={props.fieldName + "_group_" + index} label={g.label} onChange={(e) => onChangeCheckboxGroup(e, g)} key={`checkbox_group_index`}  modificators="-with-border-bottom" isChecked={g.isChecked || false}  labelModificators="-group" />
                                                }

                                            </div>
                                            {
                                                g.options && g.options.map( (o: IOption, indexOption: number) =>
                                                    <CheckboxFieldComponent value={o.value} id={props.fieldName + '_' + o.value}  name={props.fieldName}  label={o.label} onChange={onChangeCheckbox} isChecked={currentValues.includes(o.value.toString())} key={Math.random()*1000}  modificators={index === filteredOptionsGroup.length-1 && indexOption === g.options.length-1 ? '' : '-with-border-bottom' }  />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                }
            </div>

            {
                props.showFieldErrorDetail &&
                <ErrorFieldsMessagesComponent fieldsErrorsMessages={props.errors} />
            }
        </div>
    )
}
