import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {
    CampagneModificationCoordonneesService,
    ICampagneModificationCoordonneeGetApiResponse
} from "../Services/CampagneModificationCoordonneesService";
import {ICampagneModificationCoordonnee} from "../Models/CampagneModificationCoordonnee.model";

export default function useCampagneModificationCoordonnee(){
    const campagneModificationCoordonneesService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();
    const history = useHistory();

    /**
     * Récupération d'une campagne de modification de coordonnées à partir de son id
     *
     * @param {string} cmcid
     * @param {(campagneModificationCoordonnee: ICampagneModificationCoordonnee) => void} callback
     * @param {() => void} callbackError
     */
    const getCampagneModificationOfficeById = (cmcid: string, callback:(campagneModificationCoordonnee: ICampagneModificationCoordonnee) => void, callbackError?: () => void ) => {
        campagneModificationCoordonneesService.getCampagneModificationCoordonneeById(cmcid).then((response: ICampagneModificationCoordonneeGetApiResponse) => {
            if( response && response.datas.campagneModificationCoordonnee){
                if(callback){
                    callback(response.datas.campagneModificationCoordonnee);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la campagne de modification de la coordonnée",type: "danger"});
        });
    };

    return [getCampagneModificationOfficeById];
};

