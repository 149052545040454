import ApiService from "./Api.service";
import { ICampagnesModificationsGetApiResponse } from "./CampagnesModifications.service";
import {ICampagneModificationDonneeValeur} from "../Models/CampagneModificationDonnee.model";

export type ICampagneModificationDonneeFormData = {
    nouvellesValeurs: ICampagneModificationDonneeValeur[],
}

export class CampagnesModificationsDonneesService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-donnees';
    private baseRouteExternal = 'api/external/campagnes-modifications-donnees';

    /**
     * Permet la mise a jour d'une campagne de modification donnée
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public update(id: number, data: ICampagneModificationDonneeFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/update/${id}`, 'POST', data);
    }

    /**
     * Permet de valider le changement de données pour la donnée (statut: validee-par-chambre)
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public validation(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/validation/${id}`, 'POST');
    }
}