import {useHistory} from "react-router";
import {FormActions, FormComponentFormData} from "../Components/Forms/FormCreator.component";

export default function useCreationSteps() {
    const history = useHistory();

    /**
     * Permet de rediriger vers la prochaine étape de création d'un contenu
     *
     * @param {string} path
     * @param {number} id
     * @param {string} creationSteps
     */
    const redirectToNextCreationStep = (path: string, id: number, creationSteps: string[]) => {
        if (!path || !id) return;
        if (creationSteps && creationSteps.length) {
            const nextStep: string = creationSteps[0];
            history.push(path + '/' + id + nextStep);
        } else {
            history.push(path + '/' + id);
        }
    };


    /**
     * Permet de récupérer les forms actions pour les formulaires de création Steps
     *
     * @param {boolean} isOnCreationStep
     * @param {(formData: FormComponentFormData) => void} onCancel
     * @param {(formData: FormComponentFormData, goNextStep?: boolean) => void} onSubmit
     * @param {boolean} isLastStep
     * @param showBackFirstStepButton
     * @returns {FormActions[]}
     */
    const getFormActions = (isOnCreationStep: boolean, onCancel: (formData: FormComponentFormData) => void, onSubmit: (formData: FormComponentFormData, goNextStep?: boolean) => void, isLastStep: boolean = null, showBackFirstStepButton: boolean = false): FormActions[] => {
        let currentFormActions: FormActions[] = [];
        if (isOnCreationStep) {
            currentFormActions = [
                {
                    label: "Annuler",
                    modificators: "-is-link -white",
                    onAction: onCancel
                },
                {
                    label: !isLastStep ? "Enregistrer et continuer plus tard" : "Enregistrer",
                    modificators: "-secondary-border-only",
                    hasLoading: true,
                    onAction: (formData) => {
                        onSubmit(formData, false)
                    }
                },
            ];

            if (!isLastStep) {
                currentFormActions.push({
                    label: "Continuer",
                    modificators: "-secondary",
                    onAction: (formData) => {
                        onSubmit(formData, true)
                    }
                });
            }
        } else {
            currentFormActions.push(
                {
                    label: isLastStep !== null && !isLastStep ? "Continuer" : "Enregistrer",//TODO Vérifier Impact
                    modificators: "-primary",
                    hasLoading: true,
                    onAction: (formData) => {
                        onSubmit(formData, isLastStep !== null && !isLastStep)//TODO Vérifier Impact
                    }
                });
            if(isLastStep !== null && isLastStep && showBackFirstStepButton) {
                currentFormActions.push(
                    {
                        label: "Retour à l'étape 1",
                        modificators: "-is-link",
                        onAction: (formData) => {
                            formData["backFirstStepButtonPressed"] = true;
                            onCancel(formData)
                        }
                    }
                );
            }
            currentFormActions.push(
                {
                    label: "Annuler",
                    modificators: "-is-link",
                    onAction: onCancel
                }
            );
        }

        return currentFormActions;
    }


    return {redirectToNextCreationStep, getFormActions};
};

