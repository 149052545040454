import ListAsideComponent, {ListAsideAction} from "../Lists/ListAside.component";
import React, {useEffect, useState} from "react";
import usePrint from "src/Hooks/UsePrint";
import {ICampagneModificationCoordonnee} from "../../Models/CampagneModificationCoordonnee.model";
import ConfirmationComponent from "../Confirmation/Confirmation.component";


type IAsideCampagnesModificationsCoordonneesComponentProps = {
    campagneModificationCoordonnee?: ICampagneModificationCoordonnee,
    modificators?: string,
    type: "list" | "fiche",

    exportCoordonneesCallback?: () => void,
    validationEtatFinalCallback?: () => void,
    replaceCoordonneesCallback?: () => void,
    rollbackCoordonneesCallback?: () => void,

    activeTab?: string,
    filters?: any
}

export default function AsideCampagnesModificationsCoordonneesComponent(props: IAsideCampagnesModificationsCoordonneesComponentProps) {
    const [printScreen] = usePrint();

    const [actions, setActions] = useState<ListAsideAction[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [onConfirm, setOnConfirm] = useState<() => void>();
    const [confirmationText, setConfirmationText] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        let actions: ListAsideAction[] = [];

        if (props.type === "list") {
            actions.push({icon: "icon-print", click: () => printScreen()});
            actions.push({
                icon: "icon-export",
                title: "Exporter les données des coordonnées",
                click: () => props.exportCoordonneesCallback()
            });
        }

        setActions(actions);
    }, [props.type, props.filters]);

    /**
     * Annulation de la suppression
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
    }

    return (
        <>

            {
                showConfirmation &&
                <ConfirmationComponent onConfirm={onConfirm}
                                       onCancel={onCancelConfirmation}
                                       text={confirmationText}/>
            }
            <ListAsideComponent modificators={props.modificators} actions={actions} isLoading={isLoading}/>
        </>
    )
}
