import {IApiCustomResponse} from "../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../Shared/config";
import {useHistory} from "react-router";
import {AppConstants} from "../Constants/AppConstants";
import {CourriersService, ICourrierGetApiResponse} from "../Services/Courriers.service";
import {ICourrier} from "../Models/Courrier.model";



export default function useCourrier(){
    const courriersService: CourriersService = new CourriersService();
    const history = useHistory();

    /**
     * Récupération d'un courrier à partir de son id
     *
     * @param {string} cid
     * @param {(courrier: ICourrier) => void} callback
     * @param {() => void} callbackError
     */
    const getCourrierById = (cid: string, callback:(courrier: ICourrier) => void, callbackError?: () => void ) => {
        courriersService.getCourrier(cid).then((response: ICourrierGetApiResponse) => {
            if( response && response.datas.courrier){
                if(callback){
                    callback(response.datas.courrier);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathCourriers);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération du tableau de bord'",type: "danger"});
        });
    };

    return {getCourrierById};
};

