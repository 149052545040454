import {IOffice} from "src/Models/Office.model";
import CommonHelper from "src/Helpers/Common.helper";
import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "../../../Helpers/Notaires.helper";

type IOfficeInformationsComplementairesComponentProps = {
    office: IOffice,
}

export default function OfficeInformationsComplementairesComponent(props: IOfficeInformationsComplementairesComponentProps) {
    const office: IOffice = props.office;

    return (
        <div className="informations-wrapper -break-inside-avoid">
            <div className="columns">

                <div className="column">
                    <div className="informations-block">
                        <p className="label">Actif</p>
                        <p className="value">{office.actif ? CommonHelper.getFormatedOuiNon(office.actif) : "-"}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Année de création</p>
                        <p className="value">{office.anneeCreation ? office.anneeCreation : "-"}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Siren/Siret</p>
                        <p className="value">{office.sirenSiret ? office.sirenSiret : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Num. agrément</p>
                        <p className="value">{office.numeroAgrement ? office.numeroAgrement : "-"}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Office créé</p>
                        <p className="value">{office.officeCree ? CommonHelper.getFormatedOuiNon(office.officeCree) : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Début activité</p>
                        <p className="value">{office.anneeDebutActivite ? office.anneeDebutActivite : "-"}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">N° TVA</p>
                        <p className="value">{office.TVA ? office.TVA : "-"}</p>
                    </div>

                    <div className="informations-block">
                        <p className="label">Date de délivrance</p>
                        <p className="value">{office.dateDelivrance ? DateHelper.getFormatedDate(office.dateDelivrance) : "-"}</p>
                    </div>
                </div>


                <div className="column">
                    <div className="informations-block">
                        <p className="label">Notaire référent Vigilance LCB-FT</p>
                        <p className="value">{office.notaireReferentVigilanceLCBFT ? NotairesHelper.getFormattedIdentite(office.notaireReferentVigilanceLCBFT) : "-"}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}
