import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import React, {useContext} from "react";
import {useState} from "react";
import {ICampagneModificationDonnee} from "src/Models/CampagneModificationDonnee.model";
import CampagneModificationDonneeForm
    from "src/Components/Forms/CampagneModificationDonnee/CampagneModificationDonneeForm";
import {IApiCustomResponse} from "src/Services/Api.service";
import {
    CampagnesModificationsDonneesService,
    ICampagneModificationDonneeFormData
} from "../../../Services/CampagnesModificationsDonneesService";
import {IUserContext, UserContext} from "../../../Providers/User.provider";

type ICampagneModificationDonneesFormContainerProps = {
    campagneModificationOfficeId: number,
    campagnesModificationsDonnees: ICampagneModificationDonnee[],
    successSubmitForm: (response: IApiCustomResponse) => void
    modificators?: string,
}

//"Container"/Groupement de formulaires de campagne de modification d'abonnés
export default function CampagneModificationDonneesFormContainer(props: ICampagneModificationDonneesFormContainerProps) {
    const {isAuthenticated, isAdmin} = useContext(UserContext) as IUserContext;

    const [loaded, setLoaded] = useState<boolean>(true);

    const campagneModificationDonneeService: CampagnesModificationsDonneesService = new CampagnesModificationsDonneesService();

    return (
        <>
            {
                loaded &&

                <div className="campagne-form-wrapper">
                    <AccordionSimpleComponent
                        title="Explications"
                        modificators={(isAuthenticated && isAdmin) ? "" : "-no-padding-left-right"}
                        icon={"icon-informations"}
                        contentShowedOnInit={true}>
                        <div className="informations-text -fw-medium">
                            <p>
                                {/*TODO : Revoir ce texte (suivant la campagne) */}
                                Veuillez compléter le formulaire ci-dessous afin de désigner le notaire référent LCB-FT de votre office.
                            </p>
                            <p>
                                Voici la liste des différents états :<br/>
                            </p>
                            <ul>
                                <li>
                                    <strong>À traiter</strong> : Vous n'êtes pas encore intervenu sur cette donnée.
                                </li>
                                <li>
                                    <strong>À valider par la Chambre</strong> : La Chambre doit
                                    maintenant valider cette donnée.
                                </li>
                                <li>
                                    <strong>Validée par la Chambre</strong> : Cette donnée a été validée par la Chambre.
                                </li>
                                <li>
                                    <strong>Donnée remplacée</strong> : La donnée présente dans l'outil a été remplacée
                                    par la donnée que vous avez fournie.
                                </li>
                            </ul>
                        </div>
                    </AccordionSimpleComponent>

                    <AccordionSimpleComponent
                        modificators={props.modificators != null ? props.modificators : '-no-padding-left'}
                        title="Données"
                        icon="icon-informations"
                        contentShowedOnInit={true}>
                        <>
                            <p className="informations-text -fw-medium">

                            </p>
                            {
                                props.campagnesModificationsDonnees.map((campagneModificationDonnee, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="informations-block">
                                                <p className="label">État</p>
                                                <p className="value -flex">
                                                    <span
                                                        className={`state-bullet -before-element icon-bullet -${
                                                            campagneModificationDonnee.etat === 'a-valider-par-chambre' ? 'a-faire'
                                                                : campagneModificationDonnee.etat === 'validee-par-chambre' || campagneModificationDonnee.etat === 'donnee-remplacee' ? 'realisee' : ''}`}>&nbsp;</span>
                                                    {campagneModificationDonnee.etatLabel}
                                                </p>
                                            </div>
                                            <div className="campagne-form-datas" key={index}>
                                                <CampagneModificationDonneeForm
                                                    campagneModificationDonnee={campagneModificationDonnee}
                                                    onSuccessSubmit={props.successSubmitForm}
                                                    submitMethod={(formData: ICampagneModificationDonneeFormData) => campagneModificationDonneeService.update(campagneModificationDonnee.id, formData)}/>
                                            </div>
                                            {isAdmin &&
                                                <div className="informations-text -fw-medium -margin-top">
                                                    <p>
                                                        Lors de la validation, dans le cas des Notaire référent Vigilance LCB-FT, cela
                                                        va renseigner la nouvelle valeur dans les données de l'office.
                                                    </p>
                                                </div>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    </AccordionSimpleComponent>
                </div>
            }
        </>
    )
}