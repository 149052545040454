import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service";
import {IGroupe, IGroupeType} from "src/Models/Groupe.model";


export type IGroupeGetApiResponse = IApiCustomResponse & {
    datas: {
        groupe: IGroupe
    }
}
export type IGroupeTypesGetApiResponse = IApiCustomResponse & {
    datas: {
        types: IGroupeType[]
    }
}
export type IGroupesGetApiResponse = IApiCustomResponse & {
    datas: {
        groupes: IGroupe[]
    }
}
export type IGroupesAbonnesGetApiResponse = IApiCustomResponse & {
    datas: {
        abonnes: string[]
    }
}
export type IGroupesListGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IGroupesGetPaginationApi
    }
}
export type IGroupesGetPaginationApi = IApiPagination & {
    data: IGroupe[],
}
export type IGroupesGetFormData = {
    page: number,
    orderBy: string,
}
export type IGroupeInformationsFormData = {
    nom: string,
    automatique: string,
    types: string[],
    commentaires?: string,
}

export type IGroupeFormData = {}

export class GroupesService extends ApiService{

    /**
     * Permet la récupération de la liste des groupes
     *
     * @param {IGroupesGetFormData} formData
     * @returns {Promise<IGroupesListGetApiResponse>}
     */
    public getGroupes(formData: IGroupesGetFormData): Promise<IGroupesListGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes', 'GET', formData);
    }

    /**
     * Permet la récupération de tous les groupes
     * @returns {Promise<IGroupesGetApiResponse>}
     */
    public getAll(): Promise<IGroupesGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/all', 'GET');
    }

    /**
     * Permet la récupération de tous les groupes automatique
     * @returns {Promise<IGroupesGetApiResponse>}
     */
    public getAllAutomatique(): Promise<IGroupesGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/all-automatique', 'GET');
    }

    /**
     * Permet la récupération de tous les groupes non automatique
     * @returns {Promise<IGroupesGetApiResponse>}
     */
    public getAllNonAutomatique(): Promise<IGroupesGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/all-non-automatique', 'GET');
    }

    /**
     * Permet la récupération de tous abonnes au fil info
     * @returns {Promise<IGroupesGetApiResponse>}
     */
    public getAllAbonnes(): Promise<IGroupesAbonnesGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/abonnes', 'GET');
    }

    /**
     * Permet la suppression d'un groupe
     *
     * @param {number} gid
     * @returns {Promise<IApiCustomResponse>}
     */
    public delete(gid: number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid + '/delete', 'POST');
    }

    /**
     * Duplication d'un groupe
     *
     * @param {string} gid
     * @returns {Promise<IGroupeGetApiResponse>}
     */
    public duplicate(gid: number): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid + '/duplicate', 'POST');
    }

    /**
     * Permet la création d'un groupe
     *
     * @param {IGroupeInformationsFormData} formData
     * @returns {Promise<IApiCustomResponse>}
     */
    public create(formData: IGroupeInformationsFormData): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/create', 'POST', formData);
    }

    /**
     * Permet la récupération de la liste des types de groupes
     *
     * @returns {Promise<IGroupeTypesGetApiResponse>}
     */
    public getGroupesTypes(): Promise<IGroupeTypesGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/types', 'GET');
    }

    /**
     * Permet la récupération d'un groupe à partir de son id
     *
     * @param {string} gid
     * @returns {Promise<>}
     */
    public getGroupe(gid: string): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid, 'GET');
    }


    /**
     * Modification d'un groupe
     *
     * @param {string} gid
     * @param {IGroupeInformationsFormData} formDatas
     * @returns {Promise<IGroupeGetApiResponse>}
     */
    public update(gid: string, formDatas: IGroupeInformationsFormData): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid + '/update', 'POST', formDatas);
    }

    public updateGroupeMembres(gid: string, formDatas: IGroupeFormData): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid + "/membres", 'POST', formDatas);
    }

    public removeGroupeMembre(gid: number, formDatas: IGroupeFormData): Promise<IGroupeGetApiResponse>{
        return this.doApiCall('api/annuaire/groupes/' + gid + "/membres/remove", 'POST', formDatas);
    }


}
