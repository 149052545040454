import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import {IFilterData} from "../../../Hooks/FiltersHandler";
import {ICampagneModification} from "../../../Models/CampagneModification.model";
import {Link} from "react-router-dom";

type ICampagneModificationsOfficesTableListComponentProps = {
    filters: IFilterData,
    setOrder: (value: string) => void,
    campagneModification: ICampagneModification,
    campagnesModificationsOffices: ICampagneModificationOffice[],
}

export default function CampagneModificationsOfficesTableListComponent(props: ICampagneModificationsOfficesTableListComponentProps) {
    const {filters, setOrder, campagneModification, campagnesModificationsOffices} = props;
    const [colonnes, setColonnes] = React.useState([]);
    const history = useHistory();

    useEffect(() => {
        let colonnesTmp = [
            {slug: "officeCRPCEN", label: "CRPCEN", sortable: true},
            {slug: "officeNom", label: "Nom", sortable: true},
            {slug: "etat", label: "Etat *", sortable: true},
        ];

        if (campagneModification.isModificationCoordonnees) {
            colonnesTmp.push(
                {slug: "nbCampagnesModificationsCoordonnees", label: "Nb Coordonnées", sortable: false},
                {slug: "nbCampagnesModificationsCoordonneesParEtat", label: "Nb Coordonnées par Etat", sortable: false}
            );
        }

        if (campagneModification.isModificationNegociateurs) {
            colonnesTmp.push(
                {slug: "nbCampagnesModificationsNegociateurs", label: "Nb Négociateurs", sortable: false},
                {
                    slug: "nbCampagnesModificationsNegociateursParEtatValidation",
                    label: "Nb Négociateurs par Etat",
                    sortable: false
                },
            );
        }

        if (campagneModification.isModificationAbonnes) {
            colonnesTmp.push(
                {slug: "nbCampagnesModificationsAbonnes", label: "Nb Abonnés", sortable: false},
                {
                    slug: "nbCampagnesModificationsAbonnesParEtatValidation",
                    label: "Nb Abonnés par Etat",
                    sortable: false
                },
            );
        }

        if (campagneModification.isModificationDonnees) {
            colonnesTmp.push(
                {slug: "nbCampagnesModificationsDonnees", label: "Nb Données", sortable: false},
                {slug: "nbCampagnesModificationsDonneesParEtat", label: "Nb Données par Etat", sortable: false},
            );
        }

        setColonnes(colonnesTmp);
    }, [campagneModification]);


    return (
        <>
            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {colonnes.map((item, iIndex) => <th className="th" key={iIndex}>
                        <TableHeaderButtonComponent
                            direction={filters.orderType as string || AppConstants.orderType.asc}
                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                            noSort={!item.sortable}
                            label={item.label} click={(value: string) => setOrder(value)}/>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {
                    campagnesModificationsOffices && campagnesModificationsOffices.map((campagneModificationOffice: ICampagneModificationOffice, index: number) =>
                        <tr key={index}
                            onClick={() => history.push(`/annuaire/campagnes-modifications-offices/${campagneModificationOffice.id}`)}
                            className="tr">
                            <td className="td -bold">
                                <Link
                                    to={`/annuaire/campagnes-modifications-offices/${campagneModificationOffice.id}`}
                                    target="_blank">
                                    {campagneModificationOffice.office.CRPCEN}
                                </Link>
                            </td>
                            <td className="td -w200px">
                                {campagneModificationOffice.office.nom}
                            </td>
                            <td className="td">
                                {campagneModificationOffice.etatLibelle ? campagneModificationOffice.etatLibelle : ""}
                            </td>
                            {campagneModification.isModificationCoordonnees &&
                                <>
                                    <td className="td">
                                        <div>
                                            Nombre de coordonnées (total = office + notaires) :&nbsp;
                                            {campagneModificationOffice.nbCampagnesModificationsCoordonnees}
                                        </div>
                                        <div>
                                            Nombre de coordonnées (office) :&nbsp;
                                            {campagneModificationOffice.nbCampagnesModificationsCoordonneesOffice}
                                        </div>
                                        <div>
                                            Nombre de coordonnées (notaires) :&nbsp;
                                            {campagneModificationOffice.nbCampagnesModificationsCoordonneesNotaires}
                                        </div>
                                    </td>
                                    <td className="td">
                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat || {}).map((key, index) =>
                                            <div key={index}>
                                                {key} : {campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat[key]}
                                            </div>
                                        )}
                                    </td>
                                </>
                            }

                            {campagneModification.isModificationNegociateurs &&
                                <>
                                    <td className="td">
                                        {campagneModificationOffice.nbCampagnesModificationsNegociateurs}
                                    </td>
                                    <td className="td">
                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat || {}).map((key, index) =>
                                            <div key={index}>
                                                {key} : {campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat[key]}
                                            </div>
                                        )}
                                    </td>
                                </>
                            }

                            {campagneModification.isModificationAbonnes &&
                                <>
                                    <td className="td">
                                        {campagneModificationOffice.nbCampagnesModificationsAbonnes}
                                    </td>
                                    <td className="td">
                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation || {}).map((key, index) =>
                                            <div key={index}>
                                                {key} : {campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation[key]}
                                            </div>
                                        )}
                                    </td>
                                </>
                            }

                            {campagneModification.isModificationDonnees &&
                                <>
                                    <td className="td">
                                        {campagneModificationOffice.nbCampagnesModificationsDonnees}
                                    </td>
                                    <td className="td">
                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsDonneesParEtat || {}).map((key, index) =>
                                            <div key={index}>
                                                {key} : {campagneModificationOffice.nbCampagnesModificationsDonneesParEtat[key]}
                                            </div>
                                        )}
                                    </td>
                                </>
                            }
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className="g-content">
                <div className="information-text -small">
                    <p>
                        <strong>Etat *</strong> : Certains états ne sont pas mis à jour automatiquement, il s'agit d'une
                        tâche automatique lancée toutes les 15 minutes.
                    </p>
                </div>
            </div>
        </>
    )
}
