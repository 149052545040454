import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiPaginationLink} from "src/Services/Api.service";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideInspectionsComponent from "src/Components/Aside/AsideInspections.component";
import InspectionsExportComponent from "src/Components/Inspections/Inspections/InspectionsExport.component";
import {
    IInspectionsGetApiResponse,
    IInspectionsGetFormData,
    InspectionsService
} from "src/Services/Inspections.service";
import {IInspection} from "src/Models/Inspection.model";
import InspectionListTableComponent from "src/Components/Inspections/Inspections/InspectionListTable.component";
import InspectionListSuiviTable from "src/Components/Inspections/Inspections/InspectionListSuiviTable.component";
import InspectionListCourrierForm
    from "../../../Components/Forms/Inspections/Inspections/InspectionListCourrierForm.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";
import InspectionsGenerateComponent from "../../../Components/Inspections/Inspections/InspectionsGenerate.component";
import InspectionsHelper from "../../../Helpers/Inspections.helper";

type IInspectionsListScreenProps = {}

const InspectionsListScreen = (props: IInspectionsListScreenProps) => {
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [inspectionsList, setInspectionsList] = useState<IInspection[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);
    const [showGenerate, setShowGenerate] = useState<'' | 'reel' | 'test'>('');

    const location = useLocation();
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(AppConstants.pathInspections);

    const anneeDefaut: string = InspectionsHelper.getInspectionAnneeDefault();

    const inspectionsService: InspectionsService = new InspectionsService();

    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            {text: "Inspections"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, []);

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);

        //Si l'onglet actif est défini, on le garde (pour être sur le bon onglet)
        if (defaultFilters.activeTab != null && defaultFilters.activeTab.toString() !== activeTab) {
            onClickTabCallback(defaultFilters.activeTab as string);
        }
        updateFilters(defaultFilters);
    }, []);


    useEffect(() => {
        if (!filters) return null;
        if (activeTab === TabsSlugs.COURRIER) return null;

        launchFilteredSearch();
    }, [filters]);

    /**
     * Récupération des différends
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;
        setInspectionsList([]);
        setPaginationLinks(null);
        setLoaded(false);

        let activeTabParam = activeTab;
        //Si on est sur le suivi, on charge les inspections de la liste
        if (activeTab === TabsSlugs.SUIVI) {
            activeTabParam = TabsSlugs.LIST;
        }

        inspectionsService.getInspectionsList(activeTabParam, filters as IInspectionsGetFormData).then((response: IInspectionsGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newInspectionsList = response.datas.pagination.data;
                setInspectionsList(newInspectionsList);
                setPaginationLinks(response.datas.pagination.links);
            }
            setLoaded(true);
        }).catch(() => {
            setLoaded(true);
        });
    };

    /**
     * Callback lors de la recherche globale (en haut à droite)
     * @param keyword
     */
    const onGlobalSearch = (keyword: string) => {
        //Lancer la recherche que lorsque l'utilisateur tape sur entrée (ou clique sur la loupe)
        if (!filters) return;

        //Si on est sur l'onglet courrier, on ne fait rien
        if (activeTab === TabsSlugs.COURRIER) return;

        //Note importante : Lors de la recherche, pour les filtres : on garde juste : annee et activeTab
        const newFilters: IFilterData = {
            keyword: keyword,
            annee: anneeDefaut,
            activeTab: activeTab,
        };
        //Si l'année n'est pas définie (sur les filtres existants), on la définit par défaut
        if (filters.annee != null) {
            newFilters.annee = filters.annee as string;
        }

        //Si l'onglet actif n'est pas défini (sur les filtres existants), on le définit
        if (filters.activeTab != null) {
            newFilters.activeTab = filters.activeTab as string;
        }

        //On met à jour les filtres (en resetant les autres filtres)
        updateFilters(newFilters, true);
    }

    /**
     * Callback lors du changement d'onglet
     * @param tab
     */
    const onClickTabCallback = (tab: string) => {
        if (tab === TabsSlugs.COURRIER) {
            //Pour l'onglet courrier, on reset les filtres (mais on ajoute l'onglet)
            let newFilters: IFilterData = {
                activeTab: tab
            };
            updateFilters(newFilters, true);
        } else {
            //Note importante : Lors du changement d'onglet, on garde les filtres à part la page et le keyword
            let newFilters: IFilterData = {
                ...filters,
                activeTab: tab,
                page: 1,
                keyword: null,
            };
            updateFilters(newFilters, false);
        }
        setActiveTab(tab);
    };

    const getCurrentSelectedYear = () => {
        return filters != null && filters.annee != null ? filters.annee as string : anneeDefaut;
    }

    return (
        <LayoutComponent showMenu={true}>
            <div className={`list border-footer`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Inspections"
                    icon="icon-inspections"
                    currentKeyword={filters != null && filters.keyword != null ? filters.keyword as string : ''}
                    onSearch={onGlobalSearch}
                />
                <TabsContainerComponent onClickTabCallback={onClickTabCallback} defaultTab={activeTab}>
                    <TabPanelComponent label={`Liste (${getCurrentSelectedYear()})`} icon="icon-menu" slug={TabsSlugs.LIST}>
                        {activeTab === TabsSlugs.LIST &&
                            <div className="list-content -right-gutter">
                                <InspectionListTableComponent
                                    anneeDefaut={anneeDefaut}
                                    filters={filters}
                                    inspections={inspectionsList}
                                    setOrder={setOrder}
                                    updateFilters={updateFilters}/>

                                <PaginationComponent
                                    links={paginationLinks}
                                    onPageClick={page => updateFilters({page})}/>

                                {loaded &&
                                    <AsideInspectionsComponent
                                        type={TabsSlugs.LIST}
                                        exportCallback={() => setShowExport(true)}
                                        generateCallback={() => setShowGenerate('reel')}
                                        nbInspections={inspectionsList.length}
                                        filters={filters}/>
                                }
                            </div>
                        }
                        {!loaded &&
                            <LoadingComponent/>
                        }
                    </TabPanelComponent>

                    <TabPanelComponent
                        label={`Tests (${getCurrentSelectedYear()})`} icon="icon-tests" slug={TabsSlugs.LIST_TESTS}>
                        {activeTab === TabsSlugs.LIST_TESTS &&
                            <div className="list-content -right-gutter">
                                <InspectionListTableComponent
                                    anneeDefaut={anneeDefaut}
                                    filters={filters}
                                    inspections={inspectionsList}
                                    setOrder={setOrder}
                                    updateFilters={updateFilters}
                                    isTest={true}
                                />

                                <PaginationComponent
                                    links={paginationLinks}
                                    onPageClick={page => updateFilters({page})}/>

                                {loaded &&
                                    <AsideInspectionsComponent
                                        type={TabsSlugs.LIST_TESTS}
                                        exportCallback={() => setShowExport(true)}
                                        generateCallback={() => setShowGenerate('test')}
                                        nbInspections={inspectionsList.length}
                                        isTest={true} filters={filters}/>
                                }
                            </div>
                        }
                        {!loaded &&
                            <LoadingComponent/>
                        }
                    </TabPanelComponent>


                    <TabPanelComponent label={`Suivi (${getCurrentSelectedYear()})`} icon={"icon-suivi"} slug={TabsSlugs.SUIVI}>
                        {activeTab === TabsSlugs.SUIVI &&
                            <div className="list-content -right-gutter">
                                <InspectionListSuiviTable
                                    anneeDefaut={anneeDefaut}
                                    filters={filters}
                                    inspections={inspectionsList}
                                    setOrder={setOrder}
                                    updateFilters={updateFilters}/>

                                <PaginationComponent
                                    links={paginationLinks}
                                    onPageClick={page => updateFilters({page})}/>

                                {loaded &&
                                    <AsideInspectionsComponent
                                        type={TabsSlugs.SUIVI}
                                        nbInspections={inspectionsList.length}
                                        exportCallback={() => setShowExport(true)}
                                        filters={filters}/>
                                }
                            </div>
                        }
                        {!loaded &&
                            <LoadingComponent/>
                        }
                    </TabPanelComponent>


                    <TabPanelComponent label="Courrier" icon="icon-courrier" slug={TabsSlugs.COURRIER}>
                        {activeTab === TabsSlugs.COURRIER &&
                            <div className="g-content">
                                <InspectionListCourrierForm onSuccessSubmit={null}/>
                            </div>
                        }
                    </TabPanelComponent>

                </TabsContainerComponent>
            </div>

            <InspectionsExportComponent
                filters={filters}
                setShowExport={() => setShowExport(false)}
                showExport={showExport}/>

            <InspectionsGenerateComponent
                onCloseInspectionsGenerate={() => {
                    //On ferme/masquer la fenêtre de génération
                    setShowGenerate('');
                    //On relance la recherche quand on ferme la fenêtre de génération
                    launchFilteredSearch();
                }}
                showGenerate={showGenerate !== ''}
                isTest={showGenerate === 'test'}
                modificators={`${showGenerate === '' ? '-hide' : ''}`}
            />

        </LayoutComponent>
    )
};

export default InspectionsListScreen;
