import {IOption} from "../Components/Fields/Select.field.component";
import {ICourrier, ICourrierType} from "../Models/Courrier.model";
import {Module, modules} from "../Constants/ModulesConstants";
import {CourrierConstants} from "../Constants/CourrierConstants";

export default class CourriersHelper{

    /**
     * Permet de formater une liste des types de courrier pour les selects
     *
     * @param {ICourrierType[]} types
     * @returns {IOption[]}
     */
    static formatCourriersTypesForOptions(types: ICourrierType[]): IOption[]{
        if(!types || !types.length) return [];

        const options: IOption[] = [];
        for(let type of types){
            const option: IOption = {
                value: type.id.toString(),
                label: type.nom
            }

            options.push(option)
        }


        return options;
    }


    /**
     * Permet de formater une liste des années de courrier pour les selects
     *
     * @param {number[]} years
     * @returns {IOption[]}
     */
    static formatYearsForOptions(years: number[]): IOption[]{
        if(!years || !years.length) return [];

        const options: IOption[] = [];
        for(let year of years){
            const option: IOption = {
                value: year.toString(),
                label: year.toString(),
            }

            options.push(option)
        }


        return options;
    }

    static getModuleForCourrierType(courrierType: ICourrierType): string{
        const modulesOptions = modules.map((m: Module) => ({value: m.slug, label: m.name}));
        modulesOptions.push({value: "campagne-modification-coordonnees", label: "Campagne modification coordonnées"});

        return courrierType && modulesOptions.find((m) => m.value === courrierType.module)?
            modulesOptions.find((m) => m.value === courrierType.module).label : "-";
    }

    static getCurrentStatusLabel(courrier: ICourrier): string{
        if(!courrier) return "-";
        if(courrier.mailjetCurrentStatusLabel && courrier.mailjetCurrentStatusLabel !== "") return courrier.mailjetCurrentStatusLabel;

        const status = courrier.mailjetCurrentStatus;
        return CourrierConstants.mailjetStatusOptions.find((option) => option.value === status)?.label || "-";
    }
}
