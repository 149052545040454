import React, {useEffect, useState} from "react";
import {IGroupe} from "../../../Models/Groupe.model";
import {GroupesService, IGroupesGetApiResponse} from "../../../Services/Groupes.service";
import GroupesHelper from "../../../Helpers/Groupes.helper";
import SelectFieldComponent, {IOption} from "../../Fields/Select.field.component";
import moment, {Moment} from "moment/moment";
import LayoutComponent from "../../Layout/Layout.component";
import PageHeaderComponent from "../../Page/PageHeader.component";
import {IInspectionGenerateFormData, InspectionsService} from "../../../Services/Inspections.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import LoadingComponent from "../../Loading/Loading.component";
import {IApiCustomErrorResponse, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import {AppConstants} from "../../../Constants/AppConstants";
import SelectMultipleFieldComponent from "../../Fields/SelectMultiple.field.component";

type InspectionsGenerateComponentProps = {
    onCloseInspectionsGenerate: () => void,
    showGenerate: boolean,
    isTest: boolean,
    modificators: string,
}

//Fenêtre modale de génération des inspections (et/ou suppression des inspections précédentes)
export default function InspectionsGenerateComponent(props: InspectionsGenerateComponentProps) {
    const [annee, setAnnee] = useState<string>('');
    const [groupes, setGroupes] = useState<string[]>([]);

    const [supprimerUniquement, setSupprimerUniquement] = useState<string>('non');//oui/non
    const [supprimerInspectionsTests, setSupprimerInspectionsTests] = useState<string>('non');//oui/non
    const [supprimerInspections, setSupprimerInspections] = useState<string>('non');//oui/non

    const [allGroupes, setAllGroupes] = useState<IGroupe[]>([]);
    const [allGroupesLoaded, setAllGroupesLoaded] = useState<boolean>(false);

    const [yearsOptions, setYearsOptions] = useState<IOption[]>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [errorsMessages, setErrorsMessages] = useState<IApiFieldsErrorMessages>({});

    //Définition des années et des groupes (pour les champs)
    useEffect(() => {
        if (!props.showGenerate) return null;

        const newYearsOptions: IOption[] = [];

        //Sélection des années (année en cours et l"année suivante)
        const currentDate: Moment = moment();
        const currentYear: number = parseInt(currentDate.format("YYYY"));

        for (let i = 0; i < 2; i++) {
            const newYear: number = currentYear + i;
            newYearsOptions.push({value: newYear.toString(), label: newYear.toString()});
        }

        setYearsOptions(newYearsOptions);

        const groupesServices: GroupesService = new GroupesService();

        groupesServices.getAllNonAutomatique().then((response: IGroupesGetApiResponse) => {
            if (response && response.datas && response.datas.groupes) {
                setAllGroupes(response.datas.groupes);
                setAllGroupesLoaded(true);
            }
        });
    }, [props.showGenerate]);

    if (!props.showGenerate) return null;

    const onFormSubmit = () => {
        setErrorsMessages({});
        setShowErrors(false);
        setIsLoading(true);

        if (annee.trim() === '' || groupes.length === 0) {
            setShowErrors(true);

            let errors: IApiFieldsErrorMessages = {};
            errors.annee = annee.trim() === '' ? ["L'année est obligatoire."] : null;
            errors.groupes = groupes.length === 0 ? ["Veuillez choisir au moins un groupe."] : null;
            setErrorsMessages(errors);

            setIsLoading(false);
            return;
        }

        const inspectionsService: InspectionsService = new InspectionsService();

        const formData: IInspectionGenerateFormData = {
            annee: parseInt(annee),
            groupesIds: groupes.map((groupe: string) => parseInt(groupe)),
            isTest: props.isTest,
        };

        if (props.isTest) {
            formData.supprimerInspectionsTests = supprimerInspectionsTests;

            if (supprimerInspectionsTests === 'oui') {
                formData.supprimerUniquement = supprimerUniquement;
            }
        } else {
            formData.supprimerInspections = supprimerInspections;

            if (supprimerInspections === 'oui') {
                formData.supprimerUniquement = supprimerUniquement;
            }
        }

        inspectionsService.generateInspections(formData).then((response) => {
            if (response) {
                let notificationMessage: string = "La génération des inspections a bien été effectuée.";
                if (response.messages != null && response.messages.length > 0) {
                    notificationMessage = response.messages[0];
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: notificationMessage,
                    type: "success",
                });

                resetFormDatas();
                props.onCloseInspectionsGenerate();
            }
            setIsLoading(false);
        }).catch((e: IApiCustomErrorResponse) => {
            setShowErrors(true);

            let notificationMessage: string = "Une erreur est survenue lors de la génération des inspections.";
            if (e != null && e.messages != null && e.messages.length > 0) {
                notificationMessage = e.messages[0];
            }

            if (e != null && e.fieldsMessages != null && Object.keys(e.fieldsMessages).length > 0) {
                setErrorsMessages(e.fieldsMessages);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: notificationMessage,
                type: "danger",
            });

            setIsLoading(false);
        });
    }

    const resetFormDatas = () => {
        setAnnee('');
        setGroupes([]);
        setSupprimerUniquement('non');
        setSupprimerInspectionsTests('non');
        setSupprimerInspections('non');
        setErrorsMessages({});
        setShowErrors(false);
    }

    const onClose = () => {
        resetFormDatas();
        props.onCloseInspectionsGenerate();
    }

    const getBoutonLabel = () => {
        let label: string = "Générer";

        if(props.isTest) {
            if(supprimerInspectionsTests === 'oui' && supprimerUniquement === 'oui') {
                label = "Supprimer (uniquement)";
            } else if (supprimerInspectionsTests === 'oui') {
                label = "Supprimer et générer";
            }
        } else {
            if(supprimerInspections=== 'oui' && supprimerUniquement === 'oui') {
                label = "Supprimer (uniquement)";
            } else if (supprimerInspections === 'oui') {
                label = "Supprimer et générer";
            }
        }

        return label;
    }

    return (
        <>
            {
                (allGroupesLoaded && yearsOptions.length > 0) &&
                <div className={`export-screen ${props.modificators}`}>
                    <button className="export-close icon-close" onClick={onClose}/>
                    <LayoutComponent
                        showMenu={false} wrapperModificators={"-full-screen -bg-secondary"}
                        contentModificators={`-bg-white -no-flex`}>
                        <PageHeaderComponent
                            text="Inspections"
                            subText={`Génération des inspections ${props.isTest ? '(TEST)' : ''}`}
                            icon="icon-inspections"
                            modificators={`-in-fullscreen`}/>

                        <div className="d-flex">
                            <div className="description-text" style={{width: "40%", marginRight: "20px"}}>
                                <p><span className="title -bold">Points essentiels</span></p>

                                <p>
                                    Cette fenêtre vous permet de générer les
                                    inspections {props.isTest && 'de tests '}pour des offices d'un ou plusieurs groupes et une
                                    année.<br/>
                                    Elle peut aussi vous permettre de supprimer les inspections {props.isTest && 'de tests '} pour une année.
                                </p>

                                <p><span className="-bold">Attention :</span></p>

                                <ul>
                                    {props.isTest ?
                                        <li>
                                            Si vous choisissez de supprimer les tests précédents, TOUTES les inspections
                                            de tests précédentes seront supprimées <strong>avant la génération</strong>
                                        </li> :
                                        <li>
                                            Si vous choisissez de supprimer les inspections, toutes celles ayant le statut 'à faire' pour l'année définie<br />
                                            seront supprimées <strong>avant la génération</strong>, et ce, sans tenir compte des groupes sélectionnés.<br/>
                                            <strong>Si vous activez cette option, soyez vigilant : l'action est irréversible !</strong>
                                        </li>
                                    }
                                    <li>
                                        La génération ne fait pas de vérification si l'inspection existe déjà pour l'année sélectionnée.
                                    </li>
                                    <li>
                                        Le choix automatique des inspecteurs dépendra de l'année sélectionnée.
                                    </li>
                                </ul>

                                <p><span className="title -bold">Recommandations</span></p>

                                <p><span className="-bold">Groupes :</span></p>

                                <ul>
                                    <li>Veillez à créer le(s) groupe(s) avant de générer (accessible dans <em>Annuaire -- Groupes</em>).</li>
                                    <li>Veillez à saisir un nom de groupe parlant pour le retrouver facilement.</li>
                                    <li>Il s'agit d'un groupe (non automatique) contenant uniquement des offices.</li>
                                    <li>Vous pouvez par exemple créer un groupe pour les offices des inspections de 2025
                                        et un autre pour les inspections de 2026, etc.
                                    </li>
                                    <li>Si un même office figure dans plusieurs groupes, il ne sera pris en compte qu'une seule fois par le code de génération.</li>
                                    <li>Le code de génération ne récupérera que les offices actifs des groupes selectionnés en excluant les annexes.</li>
                                </ul>
                            </div>

                            <div className="">
                                <SelectMultipleFieldComponent
                                    fieldName="groupes"
                                    label="Groupe(s)"
                                    options={allGroupes ? GroupesHelper.formatListForOptions(allGroupes) : []}
                                    errors={showErrors && errorsMessages.groupes != null ? errorsMessages.groupes : null}
                                    showFieldErrorDetail={true}
                                    modificators={`-on-white`}
                                    oldValues={groupes}
                                    required={true}
                                    onChange={(values: string[]) => setGroupes(values)}/>

                                <SelectFieldComponent
                                    fieldName="annee"
                                    label="Année"
                                    options={yearsOptions}
                                    errors={showErrors && errorsMessages.annee != null ? errorsMessages.annee : null}
                                    showFieldErrorDetail={true}
                                    hideSearch={true}
                                    hideEmptyOption={true}
                                    modificators={`-on-white`}
                                    oldValue={annee}
                                    required={true}
                                    onChange={(value: string) => setAnnee(value)}/>

                                {props.isTest ?
                                    <SelectFieldComponent
                                        fieldName="supprimerInspectionsTests"
                                        label="Voulez-vous supprimer toutes les inspections de test précédentes (avant cette nouvelle génération)&nbsp;?"
                                        options={AppConstants.ouiNonOptions}
                                        errors={showErrors && errorsMessages.supprimerInspectionsTests != null ? errorsMessages.supprimerInspectionsTests : null}
                                        showFieldErrorDetail={true}
                                        modificators={`-on-white`}
                                        oldValue={supprimerInspectionsTests}
                                        hideSearch={true}
                                        hideEmptyOption={true}
                                        required={true}
                                        onChange={(value: string) => setSupprimerInspectionsTests(value)}/> :
                                    <SelectFieldComponent
                                        fieldName="supprimerInspections"
                                        label="Voulez-vous supprimer les inspections de l'année définie avant cette génération&nbsp;?"
                                        options={AppConstants.ouiNonOptions}
                                        errors={showErrors && errorsMessages.supprimerInspections != null ? errorsMessages.supprimerInspections : null}
                                        showFieldErrorDetail={true}
                                        modificators={`-on-white`}
                                        oldValue={supprimerInspections}
                                        hideSearch={true}
                                        hideEmptyOption={true}
                                        required={true}
                                        onChange={(value: string) => setSupprimerInspections(value)}/>
                                }

                                {/*Ajouter une select pour définir si on supprime uniquement ! */}
                                {(
                                        (props.isTest && supprimerInspectionsTests === 'oui') ||
                                        (!props.isTest && supprimerInspections === 'oui')
                                    ) &&
                                    <SelectFieldComponent
                                        fieldName="supprimer"
                                        label="Souhaitez-vous supprimer SANS générer&nbsp;?"
                                        options={AppConstants.ouiNonOptions}
                                        errors={showErrors && errorsMessages.supprimer != null ? errorsMessages.supprimer : null}
                                        showFieldErrorDetail={true}
                                        modificators={`-on-white`}
                                        oldValue={supprimerUniquement}
                                        hideSearch={true}
                                        hideEmptyOption={true}
                                        required={true}
                                        onChange={(value: string) => setSupprimerUniquement(value)}/>
                                }
                            </div>
                        </div>

                        <div className="form-actions -outside-right">
                            {isLoading &&
                                < LoadingComponent/>
                            }
                            <button className="g-button -is-link" onClick={onClose}>Annuler</button>
                            <button className={`g-button -primary ${isLoading ? '-loading' : ''}`}
                                    onClick={() => onFormSubmit()}
                                    disabled={isLoading}>
                                {getBoutonLabel()}
                            </button>
                        </div>

                    </LayoutComponent>
                </div>
            }
        </>
    );
}
