import {IOfficeGetApiResponse, IOfficeInformationsComplementairesFormData} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import {AppConstants} from "src/Constants/AppConstants";
import useCreationSteps from "src/Hooks/useCreationSteps";
import NotairesHelper from "../../../../Helpers/Notaires.helper";
import {IOption} from "../../../Fields/Select.field.component";
import {INotaire} from "../../../../Models/Notaire.model";
import {NotairesConstants} from "../../../../Constants/NotairesConstants";


type IOfficeInformationsComplementairesFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IOfficeInformationsComplementairesFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function OfficeInformationsComplementairesFormComponent(props: IOfficeInformationsComplementairesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'radio',
                fieldName: "actif",

                label: "Actif",
                placeholder: "Actif",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: office && office.actif ? office.actif : "",
            },
            {
                type: 'number',
                fieldName: "anneeCreation",

                label: "Année de création",
                placeholder: "Année de création",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                maxLength: 4,

                oldValue: office && office.anneeCreation ? office.anneeCreation.toString() : "",
            },
            {
                type: 'text',
                fieldName: "sirenSiret",

                label: "Siren/Siret",
                placeholder: "Siren/Siret",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.sirenSiret ? office.sirenSiret : "",
            },
            {
                type: 'text',
                fieldName: "numeroAgrement",

                label: "Num. agrément",
                placeholder: "Num. agrément",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.numeroAgrement ? office.numeroAgrement : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'radio',
                fieldName: "officeCree",

                label: "Office créé",
                placeholder: "Office créé",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,

                oldValue: office && office.officeCree ? office.officeCree : "",
            },
            {
                type: 'number',
                fieldName: "anneeDebutActivite",

                label: "Début activité",
                placeholder: "Début activité",
                required: false,
                maxLength: 4,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.anneeDebutActivite ? office.anneeDebutActivite.toString() : "",
            },
            {
                type: 'text',
                fieldName: "TVA",

                label: "N° TVA",
                placeholder: "N° TVA",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.TVA ? office.TVA : "",
            },
            {
                type: 'date',
                fieldName: "dateDelivrance",

                label: "Date de délivrance",
                placeholder: "Date de délivrance",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValue: office && office.dateDelivrance ? office.dateDelivrance : "",
            },
        ];

        //Pour le notaire choix du notaire référent Vigilance LCB-FT, on ne prend que les notaires associés et individuels
        let notairesIndividuelsAssociesOfficeOptions: IOption[] = [];
        if(office.notaires != null && office.notaires.length > 0) {
            const notairesIndividuelsAssocies: INotaire[] = props.office.notaires.filter((notaire) => {
                return notaire.type === 'notaireAssocie' || notaire.type === 'notaireIndividuel';
            });
            notairesIndividuelsAssociesOfficeOptions = NotairesHelper.formatListForOptions(notairesIndividuelsAssocies);
        }

        const formElementsColumn3: FormElement[] = [
            {
                type: 'select',
                fieldName: "notaireIdReferentVigilanceLCBFT",

                label: "Notaire référent Vigilance LCB-FT",
                required: false,
                options: notairesIndividuelsAssociesOfficeOptions,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: office && office.notaireReferentVigilanceLCBFT ? office.notaireReferentVigilanceLCBFT.id + "" : "",
            }
        ];


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true);
        setFormActions(currentFormActions);


    }, [props.office]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as IOfficeInformationsComplementairesFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: !props.isOnCreationStep ? "Les informations de l'office ont bien été modifiée." : "L'office a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations de l'office.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )

}
