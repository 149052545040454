import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LoadingComponent from "src/Components/Loading/Loading.component";
import React, {useState, useEffect} from "react";
import {IApiCustomResponse} from "src/Services/Api.service";
import CampagneModificationOfficeAccesComponent
    from "../../Components/CampagneModification/CampagneModificationOffice/CampagneModificationOfficeAcces.component";
import {ICampagneModificationOffice} from "../../Models/CampagneModificationOffice.model";
import {
    CampagnesModificationsOfficesService,
    ICampagneModificationOfficeGetApiResponse
} from "../../Services/CampagnesModificationsOffices.service";
import CampagneModificationCoordoneesFormContainer
    from "src/Components/CampagneModification/CampagneModificationCoordonnees/CampagneModificationCoordoneesFormContainer";
import CampagneModificationAbonnesFormContainer
    from "src/Components/CampagneModification/CampagneModificationAbonnes/CampagneModificationAbonnesFormContainer";
import CampagneModificationNegociateursFormContainer
    from "../../Components/CampagneModification/CampagneModificationNegociateurs/CampagneModificationNegociateursFormContainer";
import CampagneModificationDonneesFormContainer
    from "../../Components/CampagneModification/CampagneModificationDonnees/CampagneModificationDonneesFormContainer";


type ICampagneModificationOfficeExternalScreenProps = {
    ctoken: string,
}

export default function CampagneModificationOfficeExternalScreen(props: ICampagneModificationOfficeExternalScreenProps) {
    const pageHeaderTitleDefault: string = "Campagne de modification des coordonnées";
    const checkAccess: boolean = true;//todo à mettre à true pour activer la vérification de l'accès

    const [loaded, setLoaded] = useState<boolean>(true);
    const [allowAccess, setAllowAccess] = useState<boolean>(false);
    const [pageHeaderTitle, setPageHeaderTitle] = useState<string>(pageHeaderTitleDefault);
    const [campagneModificationOffice, setCampagneModificationOffice] = useState<ICampagneModificationOffice>(null);
    const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

    //Si on a pas besoin de vérifier l'accès, on charge directement les données (Note: Dev uniquement)
    useEffect(() => {
        if (!checkAccess) {
            setAllowAccess(true);
            getCampagneModificationOffice();
        }
    }, []);

    const getCampagneModificationOffice = (): void => {
        setLoaded(false);

        campagnesModificationsOfficesService.getCampagneModificationOfficeByToken(props.ctoken).then((response: ICampagneModificationOfficeGetApiResponse) => {
            setCampagneModificationOffice(response.datas.campagneModificationOffice);
            setPageHeaderTitle(pageHeaderTitleDefault + ' : ' + response.datas.campagneModificationOffice.office.nom);

            setLoaded(true);
        }).catch((error) => {
            setLoaded(true);
        });
    }

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCampagneModificationOffice();
    };

    const onSuccessAccess = (campagneModificationOffice: ICampagneModificationOffice): void => {
        setCampagneModificationOffice(campagneModificationOffice);

        setAllowAccess(true);
    }

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent
                text={pageHeaderTitle}
                icon="icon-fil-infos" logo={true}
                modificators={`-in-fullscreen -border-full-screen`}/>

            {
                !loaded &&
                <LoadingComponent/>
            }

            {(loaded && !allowAccess && checkAccess) &&
                <CampagneModificationOfficeAccesComponent
                    ctoken={props.ctoken}
                    onDefineTitle={setPageHeaderTitle}
                    onSuccessAccess={onSuccessAccess}/>
            }

            {
                (loaded && allowAccess && campagneModificationOffice !== null) &&
                <>
                    {/*Afficher que les coordonnées de l'office */}
                    {campagneModificationOffice.campagneModification.isModificationCoordonnees &&
                        <CampagneModificationCoordoneesFormContainer
                            campagnesModificationsCoordonnees={campagneModificationOffice.campagnesModificationsCoordonnees}
                            types={["office"]}
                            successSubmitForm={onSuccessSubmitForm}/>
                    }

                    {campagneModificationOffice.campagneModification.isModificationNegociateurs &&
                        <CampagneModificationNegociateursFormContainer
                            campagnesModificationsNegociateurs={campagneModificationOffice.campagnesModificationsNegociateurs}
                            successSubmitForm={onSuccessSubmitForm}/>
                    }

                    {campagneModificationOffice.campagneModification.isModificationAbonnes &&
                        <CampagneModificationAbonnesFormContainer
                            campagnesModificationsAbonnes={campagneModificationOffice.campagnesModificationsAbonnes}
                            campagneModificationOfficeId={campagneModificationOffice.id}
                            successSubmitForm={onSuccessSubmitForm}/>
                    }

                    {campagneModificationOffice.campagneModification.isModificationDonnees &&
                        <CampagneModificationDonneesFormContainer
                            campagnesModificationsDonnees={campagneModificationOffice.campagnesModificationsDonnees}
                            campagneModificationOfficeId={campagneModificationOffice.id}
                            successSubmitForm={onSuccessSubmitForm}/>
                    }
                </>
            }


        </LayoutComponent>
    )
}